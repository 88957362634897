import React from "react";
import "./Product.css";

function ProductsScreenShots({ screenShots }) {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6 col-sm-11 d-flex justify-content-center mobile_screenshots">
            <div
              style={{
                borderRadius: "30px",
                border: "4px solid #857485",
                padding: "3px",
                backgroundColor: "#1d1d1b",
              }}
            >
              <img
                src={screenShots}
                alt="Mobile Phone"
                className="img-fluid"
                style={{
                  height: "400px",
                  width: "210px",
                  borderRadius: "24px",
                  // border: "4px solid #857485",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProductsScreenShots;
