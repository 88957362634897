import { TOGGLE_MOBILE_NAV, CLOSE_MOBILE_NAV, CLOSE_DROPDOWN, OPEN_DROPDOWN } from './actions';

const initialState = {
    isMobileNavActive: false,
    isProductDropdown: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_MOBILE_NAV:
            return {
                ...state,
                isMobileNavActive: !state.isMobileNavActive,
            };
        case CLOSE_MOBILE_NAV:
            return {
                ...state,
                isMobileNavActive: false,
            };
        case OPEN_DROPDOWN:
            return {
                ...state,
                isProductDropdown: !state.isProductDropdown,
            };
        case CLOSE_DROPDOWN:
            return {
                ...state,
                isProductDropdown: false,
            };
        default:
            return state;
    }
};

export default reducer;
