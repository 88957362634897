import React, { useEffect } from "react";
import PublicHeader from "../../../core/constants/PublicHeader";
import "./IotDomain.css";
import { useDispatch, useSelector } from "react-redux";
import { toggleCloseDropdown } from "../../../redux/actions";
import DropDownProducts from "../../../core/constants/DropdownProducts";
import AlternateFooter from "../AlternateFooter";
import { IOT_CONTENT_LANDINGPAGE } from "../../../core/constants/Constant";

function IOTDomain() {
  const dispatch = useDispatch();
  const isDropDownActive = useSelector((state) => state.isProductDropdown);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      dispatch(toggleCloseDropdown());
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="container-fluid iot_domain_top p-0">
        <PublicHeader />
        {isDropDownActive && (
          <div className="col-md-12">
            <DropDownProducts />
          </div>
        )}
        <div
          className="d-flex justify-content-center align-items-center flex-column text-white text-center mt-5"
          style={{ height: "70%" }}
          data-aos="fade-up"
        >
          <div className="container-fluid d-md-flex justify-content-center align-items-center flex-column">
            <div className="iot_content_text">Internet Of Things</div>
            <h1 className="iot_heading_info mb-0" data-aos="fade-up">
              <span>I</span>
              <span>O</span>
              <span>T</span>
            </h1>
            <div
              className="col-md-6 col-sm-12 p-3 para_header_info d-flex justify-content-center"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              {IOT_CONTENT_LANDINGPAGE}
            </div>
          </div>
        </div>
      </div>
      <section className="container-fluid d-md-flex justify-content-center bg-black">
        <div className="col-md-11 project_cloud">
          <div className="col-md-12"></div>
          <div className="col-12 d-md-flex justify-content-center align-items-center text-white railway_background rounded-3 p-3 mt-3">
            At IINVSYS, we specialize in providing advanced hardware designs and
            products tailored for industries in need of enhanced connectivity
            and control. Empowering businesses across the IoT and IIoT spectrum,
            our solutions optimize operations and drive efficiency. we
            specialize in crafting innovative hardware designs and products,
            meticulously engineered for industries reliant on fluid dynamics and
            precise power management. Explore how our inventive products can
            revolutionize your industry's capabilities in IoT and IIoT domains.
          </div>
        </div>
      </section>
      <AlternateFooter />
    </React.Fragment>
  );
}

export default IOTDomain;
