import React, { useEffect, useRef, useState } from "react";
import Logo from "../../assets/homePage/company_logo.png";
import {
  Link,
  NavLink,
  useLocation,
  useParams,
  useNavigate,
} from "react-router-dom";
import { IoIosArrowBack, IoIosArrowDown, IoMdHome } from "react-icons/io";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
  MdOutlineShoppingCart,
} from "react-icons/md";
import "../../componentStyles/aboutUsPageStyle/AboutPage.css";
import nivoxLogo from "../../assets/aboutUs/image 20.png";
import sengalLogo from "../../assets/aboutUs/image 22.png";
import antarislLogo from "../../assets/aboutUs/image 21.png";
import coreValuesLogo from "../../assets/aboutUs/core_values.PNG";
import "animate.css";
import ourTeamLogo from "../../assets/aboutUs/team_men.png";
import linkedInLogo from "../../assets/aboutUs/devicon_linkedin.png";
import emailLogo from "../../assets/aboutUs/Vector.png";
import ourTeamLogoWomen from "../../assets/aboutUs/team_women.png";
import { IoIosArrowForward } from "react-icons/io";
import lifeAtIInvsys1 from "../../assets/aboutUs/life_at_iinvsys_1.png";
import lifeAtIInvsys2 from "../../assets/aboutUs/life_at_iinvsys_2.png";
import lifeAtIInvsys3 from "../../assets/aboutUs/life_at_iinvsys_3.png";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineMailOutline } from "react-icons/md";
import Footer from "../../components/landingPage/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import PureCounter from "../../core/constants/PureCounter";
import { RxHamburgerMenu } from "react-icons/rx";
import MobileNavigationHeader from "../../core/constants/MobileNavHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleCloseDropdown,
  toggleDropdown,
  toggleMobileNav,
} from "../../redux/actions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import AlternateFooter from "../landingPage/AlternateFooter";
import DropDownProducts from "../../core/constants/DropdownProducts";
import PublicHeader from "../../core/constants/PublicHeader";
import HeaderComponent from "../../core/constants/HeaderComponent";
import groupPhoto from "../../assets/aboutUs/aboutUsGroup.png";
import groupQaPhoto from "../../assets/aboutUs/qaTEAM1.png";
import spaceTeamPhoto from "../../assets/aboutUs/spaceTeam.png";
import deviceTeamPhoto from "../../assets/aboutUs/deviceTeam.png";
import aboutUsLogo from "../../assets/about-us-img.jpg";
import {
  BRANCH_ADDRESS,
  CORPORATE_ADDRESS,
} from "../../core/constants/Constant";

const imagesSlider = [
  lifeAtIInvsys1,
  lifeAtIInvsys2,
  lifeAtIInvsys3,
  lifeAtIInvsys1,
  lifeAtIInvsys2,
  lifeAtIInvsys3,
];

function AboutPage() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images] = useState([
    groupPhoto,
    groupQaPhoto,
    deviceTeamPhoto,
    spaceTeamPhoto,
  ]);
  // const [currentImage, setCurrentImage] = useState(images[currentIndex]);
  const isMobileNavActive = useSelector((state) => state.isMobileNavActive);
  const isDropDownActive = useSelector((state) => state.isProductDropdown);
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = 2;

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === totalSlides - 1 ? 0 : prevSlide + 1
    );
  };

  const goToPrevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? totalSlides - 1 : prevSlide - 1
    );
  };

  const handleToggleClick = () => {
    dispatch(toggleMobileNav());
  };

  const imagesPerPage = 3;

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => {
      // Calculate the new index
      const newIndex = prevIndex + imagesPerPage;
      // Wrap around if the new index exceeds the image array length
      return newIndex >= imagesSlider.length ? 0 : newIndex;
    });
  };

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex - imagesPerPage;
      return newIndex < 0 ? imagesSlider.length - imagesPerPage : newIndex;
    });
  };

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      console.log(element);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <React.Fragment>
      <div
        className="container-fluid d-flex justify-content-between flex-column"
        id="aboutUs_header_top"
      >
        <PublicHeader />
        {isDropDownActive && <DropDownProducts />}
        <div className="col-md-12 d-flex justify-content-center align-items-center flex-column text-white mt-5">
          <div className="col-12 d-flex justify-content-center align-items-center mt-4">
            <div className="aboutus_head d-flex justify-content-center align-items-center">
              About us
            </div>
          </div>
          <div className="col-md-11 fs-5 text-center">
            At IINVSYS, our core focus lies in the relentless innovation,
            seamless delivery, and effective implementation of technologies and
            solutions. This emphasis underscores our commitment to
            driving tangible impact and fostering innovation across industries.
            With a dedicated team of forward-thinkers, we conceptualize and
            refine big ideas into actionable strategies. We don't just stop at
            ideation; we meticulously craft solutions that address real-world
            challenges and deliver measurable results. From conceptualization to
            execution, we ensure that every project is executed with precision
            and excellence. By harnessing the latest technologies and leveraging
            our expertise, we empower our clients to stay ahead of the
            competition and navigate the complexities of the technology
            landscape with confidence. Joining IINVSYS means being part of a
            team that thrives on turning ambitious visions into reality.
            Together, we develop, deliver, and implement solutions that bring
            about lasting and transformative change for our customers and
            society as a whole.
          </div>
        </div>
      </div>
      {/* <div className="col-md-12 p-4" id="aboutus_section_two">
                <div className="row d-md-flex justify-content-center" data-aos="fade-up">
                    <div className="col-md-2 section_two_details">
                        <div className="pre_counts">
                            <PureCounter start={0} end={75} duration={1000} />+
                        </div>
                        <div className="details_title">Employees</div>
                    </div>
                    <div className="col-md-2 section_two_details">
                        <div className="pre_counts">
                            <PureCounter start={0} end={15} duration={1000} />+
                        </div>
                        <div className="details_title">Happy Collaborators</div>
                    </div>
                    <div className="col-md-2 section_two_details">
                        <div className="pre_counts">
                            <PureCounter start={0} end={30} duration={1000} />+
                        </div>
                        <div className="details_title">Project Delivered</div>
                    </div>
                    <div className="col-md-2 section_two_details">
                        <div className="pre_counts">
                            <PureCounter start={0} end={100} duration={1000} />+
                        </div>
                        <div className="details_title">Solutions</div>
                    </div>
                </div>
            </div> */}
      {/* <div className="col-md-12 bg-black p-5" >
                <div className="proud_partners_title mt-5" data-aos="fade-up">Proud Partners</div>
                <div className=" d-md-flex justify-content-center p-5 flex-row">
                    <div className="col-md-2 d-flex justify-content-center mb-5">
                        <img src={nivoxLogo} height={120} width={115} className="img-fluid" data-aos="zoom-out" data-aos-delay="300" />
                    </div>
                    <div className="col-md-4 d-flex justify-content-center mb-5">
                        <img src={antarislLogo} height={116} width={334} className="img-fluid" data-aos="zoom-out" data-aos-delay="300" />
                    </div>
                    <div className="col-md-2 d-flex justify-content-center mb-5">
                        <img src={sengalLogo} height={116} width={116} className="img-fluid" data-aos="zoom-out" data-aos-delay="300" />
                    </div>
                </div>

                <div className="col-md-12 d-md-flex justify-content-center flex-column align-items-center p-2 mt-5" data-aos="fade-up">
                    <div className="proud_partners_title">Core Values</div>
                    <div className="text-center core_value_info col-md-6 p-2">Empowering Progress through Innovation, Collaboration,
                        and Technological Inclusivity</div>

                    <div className="col-md-12 p-5 mt-4 d-md-flex justify-content-center" data-aos="zoom-in" data-aos-delay="300">
                        <img src={coreValuesLogo} className="img-fluid" />
                    </div>
                </div>
            </div> */}
      {/* Our team */}
      {/* <div className="col-md-12 bg-black d-md-flex justify-content-center flex-column align-items-center p-3">
                <div className="proud_partners_title" data-aos="zoom-in" data-aos-delay="300">Our Team</div>
                <div className="text-center core_value_info col-md-5 p-2" data-aos="zoom-in" data-aos-delay="300"> Iinvsys isjviavij ijhsdu yuwyc byuwkybrc nykcw
                    cbuwyubqc kbycqknvqwnku nb wnqkc</div>
                <div className="col-md-8 mb-4 d-md-flex justify-content-center mt-5">
                    <div className="container d-md-flex justify-content-center">
                        <div className="col-md-12 d-md-flex justify-content-center align-items-center flex-row team_box">
                            <div className="col-md-5">
                                <div className="custom-box" >
                                    <img src={ourTeamLogo} alt="Johnathan's image" className="img-fluid" style={{ maxWidth: '100%', height: '100%' }} data-aos="zoom-out" data-aos-delay="300" />

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="custom-box" data-aos="fade-up">
                                    <div className="team_content_title text-white">Johnathan</div>
                                    <div className="team_content_subtitle" data-aos="fade-up" data-aos-delay="100">Managing Director</div>
                                    <div className="mt-1 d-md-flex justify-content-around flex-column" style={{ height: '11vh' }}>
                                        <div className="team_content_info" data-aos="fade-up" data-aos-delay="200">"Everything is possible if you chose it"</div>
                                        <div className="d-md-flex align-items-center" data-aos="fade-up" data-aos-delay="300">
                                            <img src={linkedInLogo} height={16} width={16} />
                                            <img src={emailLogo} width={17} height={13} className="mx-2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container d-md-flex justify-content-center">
                        <div className="col-md-12 d-md-flex justify-content-center align-items-center flex-row team_box">
                            <div className="col-md-5">
                                <div className="custom-box">
                                    <img src={ourTeamLogoWomen} alt="Johnathan's image" style={{ maxWidth: '100%', height: '100%' }} data-aos="zoom-out" data-aos-delay="300" />

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="custom-box" data-aos="fade-up">
                                    <div className="team_content_title text-white">Max Black</div>
                                    <div className="team_content_subtitle" data-aos="fade-up" data-aos-delay="100">Executive manager</div>
                                    <div className="mt-1 d-md-flex justify-content-around flex-column" style={{ height: '11vh' }}>
                                        <div className="team_content_info" data-aos="fade-up" data-aos-delay="200">"Everything is possible if you chose it"</div>
                                        <div className="d-md-flex align-items-center" data-aos="fade-up" data-aos-delay="300">
                                            <img src={linkedInLogo} height={16} width={16} />
                                            <img src={emailLogo} width={17} height={13} className="mx-2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-8 mb-4 d-md-flex justify-content-center">
                    <div className="container d-md-flex justify-content-center">
                        <div className="col-md-12 d-md-flex justify-content-center align-items-center flex-row team_box">
                            <div className="col-md-5">
                                <div className="custom-box">
                                    <img src={ourTeamLogo} alt="Johnathan's image" style={{ maxWidth: '100%', height: '100%' }} data-aos="zoom-out" data-aos-delay="300" />

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="custom-box" data-aos="fade-up">
                                    <div className="team_content_title text-white">Johnathan</div>
                                    <div className="team_content_subtitle" data-aos="fade-up" data-aos-delay="100">Managing Director</div>
                                    <div className="mt-1 d-md-flex justify-content-around flex-column" style={{ height: '11vh' }}>
                                        <div className="team_content_info" data-aos="fade-up" data-aos-delay="200">"Everything is possible if you chose it"</div>
                                        <div className="d-md-flex align-items-center" data-aos="fade-up" data-aos-delay="300">
                                            <img src={linkedInLogo} height={16} width={16} />
                                            <img src={emailLogo} width={17} height={13} className="mx-2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container d-md-flex justify-content-center">
                        <div class="col-md-8 d-md-flex justify-content-center align-items-center flex-row team_box">
                            <div class="col-md-5">
                                <div class="custom-box">
                                    <img src={ourTeamLogoWomen} alt="Johnathan's image" style={{ maxWidth: '100%', height: '100%' }} data-aos="zoom-out" data-aos-delay="300" />

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="custom-box" data-aos="fade-up">
                                    <div className="team_content_title text-white">Max Black</div>
                                    <div className="team_content_subtitle" data-aos="fade-up" data-aos-delay="100">Executive manager</div>
                                    <div className="mt-1 d-md-flex justify-content-around flex-column" style={{ height: '11vh' }}>
                                        <div className="team_content_info" data-aos="fade-up" data-aos-delay="200">"Everything is possible if you chose it"</div>
                                        <div className="d-md-flex align-items-center" data-aos="fade-up" data-aos-delay="300">
                                            <img src={linkedInLogo} height={16} width={16} />
                                            <img src={emailLogo} width={17} height={13} className="mx-2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-8 d-md-flex justify-content-center">
                    <div className="container d-md-flex justify-content-center">
                        <div className="col-md-12 d-md-flex justify-content-center align-items-center flex-row team_box">
                            <div className="col-md-5">
                                <div className="custom-box">
                                    <img src={ourTeamLogo} alt="Johnathan's image" style={{ maxWidth: '100%', height: '100%' }} data-aos="zoom-out" data-aos-delay="300" />

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="custom-box" data-aos="fade-up">
                                    <div className="team_content_title text-white">Johnathan</div>
                                    <div className="team_content_subtitle" data-aos="fade-up" data-aos-delay="100">Managing Director</div>
                                    <div className="mt-1 d-md-flex justify-content-around flex-column" style={{ height: '11vh' }}>
                                        <div className="team_content_info" data-aos="fade-up" data-aos-delay="200">"Everything is possible if you chose it"</div>
                                        <div className="d-md-flex align-items-center" data-aos="fade-up" data-aos-delay="300">
                                            <img src={linkedInLogo} height={16} width={16} />
                                            <img src={emailLogo} width={17} height={13} className="mx-2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container d-md-flex justify-content-center">
                        <div className="col-md-8 d-md-flex justify-content-center align-items-center flex-row team_box">
                            <div className="col-md-5">
                                <div className="custom-box">
                                    <img src={ourTeamLogoWomen} alt="Johnathan's image" style={{ maxWidth: '100%', height: '100%' }} data-aos="zoom-out" data-aos-delay="300" />

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="custom-box" data-aos="fade-up">
                                    <div className="team_content_title text-white">Max Black</div>
                                    <div className="team_content_subtitle" data-aos="fade-up" data-aos-delay="100">Executive manager</div>
                                    <div className="mt-1 d-md-flex justify-content-around flex-column" style={{ height: '11vh' }}>
                                        <div className="team_content_info" data-aos="fade-up" data-aos-delay="200">"Everything is possible if you chose it"</div>
                                        <div className="d-md-flex align-items-center" data-aos="fade-up" data-aos-delay="300">
                                            <img src={linkedInLogo} height={16} width={16} />
                                            <img src={emailLogo} width={17} height={13} className="mx-2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      {/* <div className="bg-black">
        <div
          className="col-md-12 text-center d-flex justify-content-center flex-column align-items-center bg-black"
          data-aos="fade-up"
        >
          <h1 className="get_apps_title mt-5">Careers</h1>
          <div className="col-md-7 get_apps_title_content">
            We care about your smooth work experience
          </div>
        </div>

        <div className="container mt-5 bg-black">
          <div className="row">
            <div
              className="col-12 col-md-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="careers_box">
                <div className="d-md-flex justify-content-between flex-column careers_box_contents">
                  <div
                    className="d-md-flex justify-content-center flex-column"
                    style={{ height: "13vh" }}
                  >
                    <div className="careers_title">
                      Senior Frontend Developer
                    </div>
                    <div className="careers_contents">Puducherry</div>
                  </div>
                  <div className="career_apply_button">
                    <button className="careers_button">Apply</button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-12 col-md-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="careers_box">
                <div className="d-md-flex justify-content-between flex-column careers_box_contents">
                  <div
                    className="d-md-flex justify-content-center flex-column"
                    style={{ height: "13vh" }}
                  >
                    <div className="careers_title">Customer Success Agent </div>
                    <div className="careers_contents">Remote</div>
                  </div>
                  <div className="career_apply_button">
                    <button className="careers_button">Apply</button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-12 col-md-4"
              data-aos="fade-up"
              data-aos-delay="200"
              style={{ position: "relative" }}
            >
              <div className="careers_box">
                <div className="d-md-flex justify-content-between flex-column careers_box_contents">
                  <div
                    className="d-md-flex justify-content-center flex-column"
                    style={{ height: "13vh" }}
                  >
                    <div className="careers_title">Quality Analyst</div>
                    <div className="careers_contents">Remote</div>
                  </div>
                  <span className="view_all_aboutUs_career">
                    <IoIosArrowForward size={35} color="black" />
                  </span>
                  <div className="career_apply_button">
                    <button className="careers_button">Apply</button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-12 view_all_link"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <a>view all</a>
            </div>
          </div>
        </div>
      </div> */}
      <section id="life_at_iinvsys" className="life_at_iinvsys pt-5">
        <div className="container-fluid">
          <div className="section-header">
            <div>Life at IInvsys</div>
          </div>
          <div className="section-subheader pt-3">
            <div>Come Join Us!</div>
          </div>
          <Swiper
            spaceBetween={30}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
            style={{ width: "83%" }}
          >
            <div className="col-md-12">
              <SwiperSlide className="row mb-4">
                <div className="col-12 col-md-4">
                  <div className="image_slider">
                    <img
                      src={lifeAtIInvsys1}
                      alt="Image 1"
                      className="img-fluid custom-image"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="image_slider">
                    <img
                      src={lifeAtIInvsys2}
                      alt="Image 2"
                      className="img-fluid custom-image"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="image_slider">
                    <img
                      src={lifeAtIInvsys3}
                      alt="Image 3"
                      className="img-fluid custom-image"
                    />
                  </div>
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
        </div>
      </section>
      <section id="our_location_aboutus" className="our_location_aboutus">
        <div
          className="col-9 d-md-flex flex-column gap-3 justify-content-evenly align-items-center"
          id="our_location_main"
        >
          <div className="col-md-5 p-1 d-flex justify-content-center align-items-center get_apps_title">
            Our Location
          </div>
          {currentSlide === 0 && (
            <div
              className="col-md-8 d-md-flex justify-content-center align-items-center"
              style={{ position: "relative" }}
            >
              <div className="col-md-6 p-4 d-flex justify-content-center gap-2 head_q1_main align-items-start flex-column">
                <div className="our_location_subhead">
                  <CiLocationOn size={15} /> Puducherry Office:
                </div>
                <div
                  className="d-flex justify-content-end flex-column"
                  // style={{ height: "7vh" }}
                >
                  <div className="our_location_contents">
                    1st Floor, JK Towers, 100 Feet Road, Puducherry - 605013
                  </div>
                  {/* <div className="our_location_contents">
                   <MdOutlineMailOutline size={15} /> support@iinvsys.com
                 </div> */}
                </div>
              </div>

              <div className="col-md-8 border d-flex justify-content-center head_q1_aboutus align-items-start flex-column">
                <iframe
                  src={BRANCH_ADDRESS}
                  style={{
                    borderRadius: "10px",
                    height: "100%",
                    width: "100%",
                  }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          )}
          {currentSlide === 1 && (
            <div
              className="col-md-8 d-md-flex justify-content-center align-items-center"
              style={{ position: "relative" }}
            >
              <div className="col-md-6 p-4 d-flex justify-content-center gap-2 head_q1_main align-items-start flex-column">
                <div className="our_location_subhead">
                  <CiLocationOn size={15} /> Chennai Office:
                </div>
                <div className="d-flex justify-content-end flex-column">
                  <div className="our_location_contents">
                    Suite No.903 & 904, 9th Floor, “TVH BELICIAA TOWERS II”,
                    Block No.94, No.71/9 (71/19), M.R.C. Nagar Main Road ,
                    M.R.C. Nagar, Raja Annamalai Puram, Chennai – 600028
                  </div>
                  {/* <div className="our_location_contents"></div> */}
                </div>
              </div>

              <div className="col-md-8 border d-flex justify-content-center head_q1_aboutus align-items-start flex-column">
                <iframe
                  src={CORPORATE_ADDRESS}
                  style={{
                    borderRadius: "10px",
                    height: "100%",
                    width: "100%",
                  }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          )}
          <div className="d-flex justify-content-center align-items-center">
            <div
              className={`d-flex gap-2 arrow_left ${
                currentSlide === 0 ? "disabled" : ""
              }`}
              onClick={currentSlide !== 0 ? goToPrevSlide : null}
            >
              <MdOutlineKeyboardArrowLeft size={20} color="#fff" />
            </div>
            <div className="d-flex justify-content-center text-white branch_office">
              {currentSlide === 0 ? "Puducherry Office" : "Chennai Office"}
            </div>
            <div
              className={`d-flex gap-2 arrow_right ${
                currentSlide === totalSlides - 1 ? "disabled" : ""
              }`}
              onClick={currentSlide !== totalSlides - 1 ? goToNextSlide : null}
            >
              <MdOutlineKeyboardArrowRight size={20} color="#fff" />
            </div>
          </div>
        </div>
      </section>
      <AlternateFooter
        chennaiLocation={
          currentSlide !== totalSlides - 1 ? goToNextSlide : null
        }
        pondyLocation={currentSlide !== 0 ? goToPrevSlide : null}
      />
    </React.Fragment>
  );
}

export default AboutPage;
