import { RxHamburgerMenu } from "react-icons/rx";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleCloseDropdown,
  toggleDropdown,
  toggleMobileNav,
} from "../../redux/actions";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Logo from "../../assets/homePage/company_logo.png";
import MobileNavigationHeader from "./MobileNavHeader";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { useMediaQuery } from "react-responsive";
import Img1 from "../../assets/homePage/company_logo.png";

function PublicHeader() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDropDownActive = useSelector((state) => state.isProductDropdown);
  const isMobileNavActive = useSelector((state) => state.isMobileNavActive);
  const isTabletMode = useMediaQuery({
    query:
      "(min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1)",
  });
  const triggerDropdown = () => {
    dispatch(toggleDropdown());
    // event.stopPropagation();
  };

  const handleToggleClick = () => {
    dispatch(toggleMobileNav());
  };

  useEffect(() => {
    return () => {
      dispatch(toggleCloseDropdown());
    };
  }, [dispatch]);

  const onReloadHome = () => {
    navigate("/");
    window.location.reload();
  };

  return (
    <header
      id="header"
      className="header d-flex align-items-center flex-column"
    >
      <div className="container-fluid d-flex align-items-center justify-content-between">
        <div
          href="/"
          className={`${
            // isTabletMode
            "col-md-7 logo d-md-flex justify-content-center gap-2 align-items-center headerImg_content"
            // : "col-md-7 logo d-md-flex justify-content-around align-items-center headerImg_content"
          }`}
        >
          <img
            src={Logo}
            alt="company_logo"
            className="image-fluid company_logo"
            onClick={onReloadHome}
            style={{ cursor: "pointer" }}
          />
          <hr className="vertical_line" />
          <h1
            className="text-white heading_info"
            style={{ fontSize: "20px", textDecoration: "none" }}
          >
            Democratizing Technology,{" "}
            <span className="info_text">Driving Innovation</span>
          </h1>
        </div>
        <nav id="navbar" className="navbar">
          <ul>
            <li>
              <NavLink to={"/"} className="mt-3">
                Home
              </NavLink>
            </li>
            <li onClick={triggerDropdown}>
              <Link
                style={{ color: `${isDropDownActive ? "#055783c7" : ""}` }}
                className="mt-3"
              >
                Products
                <span className="mx-1">
                  {!isDropDownActive ? (
                    <IoIosArrowForward size={15} color="#fff" />
                  ) : (
                    <IoIosArrowDown size={15} color="#fff" />
                  )}
                </span>
              </Link>
            </li>

            {/* <li><NavLink to={"/getsupport-page"}>Support</NavLink></li> */}
            <li>
              <NavLink className="p-2 mt-3" to={"/aboutus-page"}>
                About Us
              </NavLink>
            </li>
            {/* <li className="dropdown"><a href=""><span>Products</span> <i className="bi bi-chevron-down dropdown-indicator"></i></a>
                        <ul>
                            <li><NavLink to={"/smazer-product"}>Smazer</NavLink></li>
                            <li><NavLink to={"/szephyr-product"}>sZephyr</NavLink></li>
                            <li><NavLink to={"/smampmax-product"}>SmAmp Max</NavLink></li>
                        </ul>
                    </li> */}
          </ul>
        </nav>

        {/* <div className="service_header_shopping">
                <a href="#" className=""><span className="shopping_cart_aboutUs"><MdOutlineShoppingCart size={35} /></span></a>
                <div className="mobile-nav-toggle" onClick={handleToggleClick}>
                    {isMobileNavActive ? "" : <RxHamburgerMenu />}
                </div>
            </div> */}
        <div className="position-relative">
          {/* <a href="#" className="mx-2"><span className="shopping_cart"><MdOutlineShoppingCart size={35} /></span></a> */}
          {/* <a href="#" className="mx-2 js-search-open">
            <span className="bi-search"></span>
          </a> */}
          <div className="mobile-nav-toggle" onClick={handleToggleClick}>
            {isMobileNavActive ? "" : <RxHamburgerMenu />}
          </div>
        </div>
        <MobileNavigationHeader
          toggleClick={handleToggleClick}
          mobileActive={isMobileNavActive}
        />
      </div>
      {/* <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 d-flex justify-content-end align-items-center border">
            <div className="border col-4 p-3">
              <div className="col-10">
                <img src={Img1} className="img-fluid  " alt="..." />
              </div>
              <div
                className="col-12 d-flex justify-content-center align-items-center flex-column"
              >
                <NavLink
                  to={"/about-us"}
                  className={`col-12  p-1 d-flex sidebox1 align-items-center justify-content-start gap-3  `}
                >
                  About Us
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </header>
  );
}

export default PublicHeader;
