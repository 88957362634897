import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import "./Product.css";

function FAQ(props) {
  const faqArray = [
    {
      faqTitle: "How to check whether my Geyser is compatible with SmaZer",
      faqAns:
        "SmaZer is compatible with all models of Geyser including instant and storage tank water heaters",
    },
    {
      faqTitle: "I don't have internet at home. Can I still use SmaZer",
      faqAns:
        "Absolutely. Please note that internet is not mandatory for operation of SmaZer when in closer range. However, remote connectivity needs internet",
    },
    {
      faqTitle: "Why I can't connect my SmaZer with my router",
      faqAns:
        "Please check whether your router is 5G. SmaZer works best with 4G router. You can connect to a 4G router for seamless connectivity",
    },
    {
      faqTitle: "How I can share my SmaZer control with my family",
      faqAns:
        "Please ask them to download and install SmaZer App in their smartphone. You can share your device with them using the Share icon available at the application homepage",
    },
    {
      faqTitle: "I don't have my phone with me. Can I still use SmaZer",
      faqAns:
        "Yes. You can turn ON the Geyser, by pressing the power button manually",
    },
    {
      faqTitle: "How does SmaZer work",
      faqAns:
        "SmaZer senses the temperature of the water coming out of Geyser in real time and as per your settings in the App, based on the threshold temperature, your Geyser will be turned OFF when the desired temperature is reached",
    },
    {
      faqTitle: "How much energy is saved by using SmaZer",
      faqAns:
        "Based on the usage pattern and the desired level of comfort set by the user SmaZer aims to save maximum energy without compromising your comfort. The amount of energy saved can be seen in the application homepage itself",
    },
    // {
    //     faqTitle: "Where should I register my product",
    //     faqAns: "Please go to the Warranty page here to register your product"
    // },
    {
      faqTitle: "What's in the box",
      faqAns:
        "Box content: 1 Nos SmaZer Adapter; 1 Nos SmaZer sensor; 1 Nos connecting cable; 1 Nos product manual",
    },
    // {
    //     faqTitle: "Where should I check for the Warranty of my product",
    //     faqAns: "Please check the warranty of your SmaZer in the Warranty page here (Hyperlink)"
    // },
    {
      faqTitle: "Can I install SmaZer myself",
      faqAns:
        "You can If you have a suitable wrench and some teflon tape with you. The installation is super easy. However, you can seek help of a professional plumber for the installation.",
    },
    {
      faqTitle: "Can I control SmaZer using my smart speaker",
      faqAns: "Yes, SmaZer works with Alexa, Google Assistant and Siri",
    },
    {
      faqTitle:
        "LED in SmaZer is continuously blinking in blue color. What does it mean",
      faqAns:
        "It means that SmaZer has entered pairing mode. Please proceed with the steps for pairing with your smartphone",
    },
    {
      faqTitle: "Pairing is not successful. What I should do",
      faqAns:
        "You can retry the pairing process. Please make sure you restart the device before continuing to retry the pairing process",
    },
  ];

  const [openFaqIndex, setOpenFaqIndex] = useState(null);

  const triggerFaqDropdown = (index) => {
    setOpenFaqIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <React.Fragment>
      <section id="frequently_asked_questions">
        <div className="container-fluid d-md-flex justify-content-center flex-column align-items-center pb-5">
          <div className="col-md-12 d-flex justify-content-center flex-column align-items-center pb-5">
            <div className="col-md-12  text-white d-flex justify-content-center align-items-center flex-column">
              <div className="customers_review_text">{props.title}</div>
              <div className="customers_review_info">{props.subTitle}</div>
            </div>
            <div
              className="col-md-9 mt-5 p-4 d-flex justify-content-center align-items-center flex-column"
              id="faq_container"
              ref={props.refs}
            >
              <div
                className="col-md-12 d-flex text-white justify-content-between align-items-center"
                onClick={props.trigger}
                style={{ cursor: "pointer", width: "100%" }}
              >
                <div className="col-md-10 p-2 faq_title">Smazer FAQs</div>
                <div className="col-md-2 p-2 d-flex justify-content-center align-items-center">
                  {props.showFaq ? (
                    <IoIosArrowUp size={30} style={{ cursor: "pointer" }} />
                  ) : (
                    <IoIosArrowDown size={30} style={{ cursor: "pointer" }} />
                  )}
                </div>
              </div>
              <div className="col-md-12 d-flex justify-content-around flex-row flex-wrap bg-white rounded-4">
                {props.showFaq &&
                  faqArray.map((data, index) => (
                    <div
                      key={index}
                      className={`col-md-5 p-3 d-flex justify-content-center flex-column 
                                         faq_contents ${
                                           index === 11 || index === 13
                                             ? "border-bottom-0"
                                             : ""
                                         }`}
                      style={{ width: "100%" }}
                    >
                      <div
                        className="faq_contents_items text-dark"
                        style={{ height: "100%" }}
                      >
                        <div
                          className="col-md-12 d-flex justify-content-start align-items-center"
                          style={{ cursor: "pointer" }}
                          onClick={() => triggerFaqDropdown(index)}
                        >
                          <div className="col-md-10 p-2 faq_title">
                            <strong>{data.faqTitle} ?</strong>
                          </div>
                          <div className="col-md-2 p-2 d-flex justify-content-center align-items-center">
                            {/* <IoIosArrowForward size={30}
                                                        style={{ cursor: 'pointer' }} /> */}
                          </div>
                        </div>
                        {
                          <div className="col-md-12 d-flex justify-content-start align-items-center rounded-2">
                            <div className="col-md-10 p-2 faq_title">
                              {data.faqAns}
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default FAQ;
