import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../../assets/homePage/company_logo.png";
import { IoMdHome } from "react-icons/io";
import { MdOutlineShoppingCart } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";
import MobileNavigationHeader from "../../../core/constants/MobileNavHeader";
import "./CloudDomain.css";
import AlternateFooter from "../AlternateFooter";
import cloudDomainBg from "../../../assets/homePage/Domains/cloudSplineImg1.png";
import {
  toggleCloseDropdown,
  toggleDropdown,
  toggleMobileNav,
} from "../../../redux/actions";
import DropDownProducts from "../../../core/constants/DropdownProducts";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import PublicHeader from "../../../core/constants/PublicHeader";
import { CLOUD_CONTENT_LANDINGPAGE } from "../../../core/constants/Constant";

function CloudDomain() {
  const dispatch = useDispatch();
  const isMobileNavActive = useSelector((state) => state.isMobileNavActive);
  const isDropDownActive = useSelector((state) => state.isProductDropdown);

  const handleToggleClick = () => {
    dispatch(toggleMobileNav());
  };

  const triggerDropdown = () => {
    dispatch(toggleDropdown());
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      dispatch(toggleCloseDropdown());
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="container-fluid cloud_domain_top p-0">
        <PublicHeader />
        {isDropDownActive && <DropDownProducts />}
        <div
          className="col-md-12 d-flex justify-content-start align-items-center flex-column text-center"
          style={{ height: "70%" }}
          data-aos="fade-up"
        >
          <div className="col-md-12 d-flex justify-content-center flex-column text-center align-items-center">
            <h1 className="cloud_heading_info mb-0 p-0 mt-5" data-aos="fade-up">
              Cloud
            </h1>
            <div className="computing_text">Computing</div>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <div
                className="col-md-7 col-sm-12 para_header_info p-3"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                {CLOUD_CONTENT_LANDINGPAGE}
              </div>
              {/* <button
                className="btn btn-primary cloud_button mt-3"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                Contact Us Now
              </button> */}
              <div className="col-md-4 mt-3 d-flex justify-content-center align-items-center">
                <img
                  className="img-fluid"
                  style={{ width: "100%", height: "100%" }}
                  src={cloudDomainBg}
                  data-aos="zoom-out"
                  data-aos-delay="400"
                />
              </div>
            </div>
          </div>
        </div>
        <section className="container-fluid mt-3 d-md-flex justify-content-center">
          <div className="col-md-11 project_cloud">
            <div className="col-md-12">
              {/* <div className="col-md-7 our_projects">Our Projects:</div> */}
            </div>
            <div className="col-12 d-md-flex justify-content-center align-items-center text-white project_box p-3 mt-3">
              At IINVSYS, we are your trusted partner for creating, running, and
              managing applications. We offer a platform and services that
              enable you to have your own custom applications on the cloud or
              the edge, depending on your needs. You don’t have to worry about
              the underlying infrastructure or the technical details. We handle
              everything for you, from design, implementation, testing, launch,
              update, to maintenance. You can access your applications from any
              web browser and enjoy the benefits of automatic maintenance and
              updates. We deliver software applications over the internet as a
              service that you can subscribe to. With IINVSYS, you can focus on
              your core business and leave the rest to us.
              {/* <div className="col-md-7 p-1 d-md-flex justify-content-center flex-wrap align-items-center ">

                                <div className="col-md-6 p-1">
                                    <div className="col-md-12 p-1 project_box" style={{ height: "234px" }}></div>
                                </div>
                                <div className="col-md-6 p-1">
                                    <div className="col-md-12 p-1 project_box" style={{ height: "234px" }}></div>
                                </div><div className="col-md-6 p-1">
                                    <div className="col-md-12 p-1 project_box" style={{ height: "115px" }}></div></div>
                                <div className="col-md-6 p-1">
                                    <div className="col-md-12 p-1 project_box" style={{ height: "115px" }}></div></div>

                            </div>
                            <div className="col-md-5 p-1  d-md-flex flex-column gap-3 justify-content-center align-items-center">
                                <div className="col-md-12 p-1 project_box" style={{ height: "175px" }}></div>
                                <div className="col-md-12 p-1 project_box" style={{ height: "175px" }}></div>
                            </div> */}
            </div>
          </div>
        </section>
        <AlternateFooter />
      </div>
    </React.Fragment>
  );
}

export default CloudDomain;
