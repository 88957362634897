export const TOGGLE_MOBILE_NAV = 'TOGGLE_MOBILE_NAV';
export const CLOSE_MOBILE_NAV = 'CLOSE_MOBILE_NAV';
export const OPEN_DROPDOWN = 'OPEN_DROPDOWN_PRODUCTS';
export const CLOSE_DROPDOWN = 'CLOSE_DROPDOWN_PRODUCTS';


export const toggleMobileNav = () => ({
  type: TOGGLE_MOBILE_NAV,
});

export const closeMobileNav = () => ({
  type: CLOSE_MOBILE_NAV,
});

export const toggleDropdown = () => ({
  type: OPEN_DROPDOWN,
});

export const toggleCloseDropdown = () => ({
  type: CLOSE_DROPDOWN,
});