import axios from "axios";
import { BASE_URL, BASE_URL2 } from "./baseUrl";

let config = {
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://103.109.44.52:55534",
        "Access-Control-Allow-Methods":
          "GET,HEAD,PUT,OPTIONS,PATCH,POST,DELETE,OPTIONS",
    },
};

export const formSubmission = async (payload) => {
    console.log("supportForm_payload: " + JSON.stringify(payload));
    try {
        const response = await axios.post(
            `${BASE_URL2}`,
            payload,
            config
        );
        return response.data;
        // return response.data;
    } catch (error) {
        console.log("supportForm_payload", error);
        throw error;
    }
};
