import React from "react";
// import "../../componentStyles/landingPageStyle/Services.css";
import "../../componentStyles/landingPageStyle/Services.css";
import { serviceProvideModal } from "../../core/constants/Constant";

function Services() {
  return (
    <React.Fragment>
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-12 text-center mb-5">
            <h1 className="serviceProvide_title">
              <span className="alternate_sub_color">Solutions</span> we provide
            </h1>
          </div>
        </div>

        <div className="row mt-4 d-md-flex justify-content-center align-items-center">
          {serviceProvideModal.map((serviceData, index) => (
            <div
              className="col-md-6 mb-5"
              style={{ cursor: "pointer" }}
              key={index}
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div
                className="card custom-card"
                style={{
                  background: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 100)), url(${serviceData.background})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "bottom",
                }}
              >
                <div className="card-body custom-card-body mb-4">
                  <h5 className="card-title text-white serviceProvide_subhead">
                    {serviceData.title}
                  </h5>
                  <p className="card-text text-white serviceProvie_content">
                    {serviceData.content}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Services;
