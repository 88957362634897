import Spline from "@splinetool/react-spline";
import React from "react";

export default function CombinedSzephyrColor({ colorType }) {
  return (
    <React.Fragment>
      {colorType === "White" && <SplineViewerSzephyrWhite />}
      {colorType === "Blue" && <SplineViewerSzephyrBlue />}
      {colorType === "Green" && <SplineViewerSzephyrGreen />}
      {colorType === "Grey" && <SplineViewerSzephyrGrey />}
    </React.Fragment>
  );
}

function SplineViewerSzephyrWhite() {
  return (
    <Spline scene="https://prod.spline.design/QJtG5cucqH9m23ii/scene.splinecode" />
  );
}

function SplineViewerSzephyrBlue() {
  return (
    <Spline scene="https://prod.spline.design/TYmBAEFltYi9WMoq/scene.splinecode" />
    
  );
}

function SplineViewerSzephyrGreen() {
  return (
    <Spline scene="https://prod.spline.design/9UREciNSrJTO6LPM/scene.splinecode"/>
    
  );
}

function  SplineViewerSzephyrGrey() {
  return (
    <Spline scene="https://prod.spline.design/kOqdxhst9nFoR0mV/scene.splinecode" />
  );
}


// function SplineViewerSzephyrWhite() {
//   return (
//     <Spline scene="https://prod.spline.design/xGYsDAben4V0qT9i/scene.splinecode" />
//   );
// }