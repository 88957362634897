import systemArchBg from "../../assets/servicePage/cardImages/system_arch.png";
import hardWareBg from "../../assets/servicePage/cardImages/hardware_design.png";
import embeddedBg from "../../assets/servicePage/cardImages/embedded_design.png";
import cloudCompBg from "../../assets/servicePage/cardImages/cloud_comp.png";
import appDevBg from "../../assets/servicePage/cardImages/app_develop.png";
import webDevBg from "../../assets/servicePage/cardImages/web_develop.png";
import fabricBg from "../../assets/servicePage/cardImages/fabrication.png";
import mechbg from "../../assets/servicePage/cardImages/mech_design.png";
import assemblyBg from "../../assets/servicePage/cardImages/assembly.png";
import throughQualityBg from "../../assets/servicePage/cardImages/through_quality.png";
import railwayLogo from "../../assets/homePage/railway.png";
import cloudLogo from "../../assets/homePage/cloud_computing_pic.png";
import iotLogo from "../../assets/homePage/iot_pic.png";
import satelliteLogo from "../../assets/space-background.webp";

export const serviceProvideModal = [
  {
    title: "System Architecture",
    content:
      "Architecting robust and scalable systems, our expertise in system architecture ensures the foundation for technologically advanced solutions that stand the test of time",
    background: systemArchBg,
  },
  {
    title: "Hardware Design",
    content:
      "Innovate with confidence through our expert hardware design services, where precision and creativity converge to bring your ideas to life with cutting-edge technology.",
    background: hardWareBg,
  },
  {
    title: "Embedded SW Design",
    content:
      "From conceptualization to final product fabrication, our expertise in Embedded Software Design ensures a comprehensive, seamless, and innovative end-to-end process",
    background: embeddedBg,
  },
  {
    title: "Cloud Computing",
    content:
      "Harness the power of the cloud with our industry-leading solutions, delivering scalable and secure cloud computing services to optimize your business operations and drive innovation",
    background: cloudCompBg,
  },
  {
    title: "App Development",
    content:
      "Empowering business with robust mobile applications. Our app development services guarantee intuitive, feature-rich applications that elevate user engagement and satisfaction",
    background: appDevBg,
  },
  {
    title: "Web Development",
    content:
      "Crafting exceptional web experiences, our expertise in web development ensures seamless and visually stunning online platforms tailored to customer's unique business",
    background: webDevBg,
  },
  // {
  //     title: 'Fabrication',
  //     content: 'Content for Card 4.',
  //     background: fabricBg
  // },
  {
    title: "Mechanical Design",
    content:
      "Revolutionizing mechanical design, we think beyond boundaries to bring customer's vision to life, ensuring cost effective solutions that exceeds the customer's expectation",
    background: mechbg,
  },
  // {
  //     title: 'Assembly',
  //     content: 'Seamless integration and precision define our product assembly services, where components come together flawlessly to create products that exceed expectations.',
  //     background: assemblyBg
  // },
  // {
  //     title: 'Through Quality Check',
  //     content: 'Transform concepts into reality with our meticulous fabrication services, ensuring the production of high-quality components that meet the most stringent standards',
  //     background: throughQualityBg
  // },
];

// Vision and Missions
export const VISION_CONTENTS = `Revolutionizing the world by democratizing technology through relentless innovation.`;

export const MISSION_CONTENTS = `To empower society, global industries and enterprises
through the delivery of cutting-edge, cost-effective,
and application-rich solutions. We specialize in artificial intelligence, machine
intelligence, mobile applications, IoT, and IIoT
hardware and software products, accompanied by
value-added services. Our commitment is to seamlessly
integrate innovation into common households,
businesses, fostering efficiency and growth for both
our customers and partners worldwide.`;

// Landing Page Domain Contents
export const IOT_CONTENT_LANDINGPAGE = `Launching several smart consumer electrical and electronics, IoT and IIoT products 
  manufactured in India for households and industries. Contributing to the energy conservation 
  efforts, reduce carbon emissions, and drive economic growth through local manufacturing and 
  innovation. Creating significant impact on energy efficiency and cost savings at scale, benefiting 
  both consumers, industries and the environment.`;
export const CLOUD_CONTENT_LANDINGPAGE = `Pioneering Android and iOS mobile applications and web application with in-house creative 
  UX/UI design, modular and scalable front end/back end design. Providing single/multi-user 
  authentication, secure connectivity, Wi-Fi, BLE, IoT, IIoT, virtual assistance, e-commerce, 
  social connect, CRM, real-time data synchronization, cloud (AWS, Azure) connectivity, 
  dashboard data display, and mobile edge computing.`;
export const RAILWAY_CONTENT_LANDINGPAGE = `Partnering with industry leaders, we provide optical media converters, rugged routers, modems and 
  control systems for Indian railways. As an emerging leader and trusted partner, we provide 
  comprehensive solutions for the railway industry in India and beyond. We focus on innovation, 
  addressing specific railway needs, and drive continued growth in the railway sector.`;
export const SPACE_CONTENT_LANDINGPAGE = ` We are pioneers in delivering cutting-edge solutions for
companies delivering state-of-the-art avionics hardware systems and solutions to our esteemed 
customers/partners. Very proud to be part of the remarkable mission (2023 launch of Antaris 
Janus1 in SSLV D2) powered by Antaris™ SatOS, the world’s first Software for Space. Conducting 
avionics system design, HW/SW integration, SDR and ADCS integration and working with the 
customers/partners for several interesting, mission critical, upcoming satellite launches.`;

export const DOMAINS = [
  {
    name: "IoT / IIoT",
    logo: iotLogo,
    contents: IOT_CONTENT_LANDINGPAGE,
    path: "/iot",
  },
  // {
  //   name: "Cloud",
  //   logo: cloudLogo,
  //   contents: CLOUD_CONTENT_LANDINGPAGE,
  //   path: "/cloud",
  // },
  {
    name: "Railways",
    logo: railwayLogo,
    contents: RAILWAY_CONTENT_LANDINGPAGE,
    path: "/railway",
  },
  {
    name: "Space",
    logo: satelliteLogo,
    contents: SPACE_CONTENT_LANDINGPAGE,
    path: "/space",
  },
];
// Play store and App store links

// Smazer
export const SMAZER_PLAY_STORE =
  "https://play.google.com/store/apps/details?id=com.geezer";
export const SMAZER_APP_STORE =
  "https://apps.apple.com/za/app/smazer/id1638898568";

// Szephyr
export const SZEPHYR_PLAY_STORE =
  "https://play.google.com/store/apps/details?id=com.iinvsys.szephyr&hl=en_IN&gl=US";
export const SZEPHYR_APP_STORE =
  "https://apps.apple.com/za/app/szephyr/id6447295364";

// SmampMax
export const SMAMPMAX_PLAY_STORE =
  "https://play.google.com/store/apps/details?id=com.iinvsys.smampmax&hl=en_IN&gl=US";
export const SMAMPMAX_APP_STORE =
  "https://apps.apple.com/us/app/smamp-max/id1658250676";

export const scrollToDiv = (targetId) => {
  const targetDiv = document.getElementById(targetId);
  if (targetDiv) {
    targetDiv.scrollIntoView({ behavior: "smooth" });
  }
};

// Location Links
export const CORPORATE_ADDRESS =
  "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d243.15589558399046!2d80.2285815!3d12.8113014!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525a7ef1c50c5b%3A0xf10c1441d41bdd2d!2sAKSHAYA%20HQ%2C%20Padur%2C%20Tamil%20Nadu%20603103!5e0!3m2!1sen!2sin!4v1712647897331!5m2!1sen!2sin";
export const BRANCH_ADDRESS =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3903.5090357086615!2d79.80586707410392!3d11.939217236672825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5361733c22a727%3A0x9c4411d46c2c323f!2sJ.K.%20Towers!5e0!3m2!1sen!2sin!4v1715754481098!5m2!1sen!2sin";

// Customer Reviews

export const SZEPHYR_CUSTOMER_REVIEWS = [
  {
    customerName: "Ananya pandey",
    review:
      "sZephyr is a game-changer for existing ACs! Reduced bills, voice control, and analytics - it's a smart home dream. Recommending to all for efficient and comfortable living!",
  },
  {
    customerName: "Rishi Gupta",
    review:
      "sZephyr, a marvel for ACs! Voice control, scheduling, and analytics make it a must-have. Reduced power consumption without compromising comfort. A smart choice!",
  },
  {
    customerName: "Riya Sharma",
    review:
      "I used to forget to turn OFF my AC all the time. Now sZephyr takes care of it so I don't need to worry anymore. Thanks to sZephyr. Purchase worthy of my money!",
  },
  {
    customerName: "Arun Prasad",
    review:
      "I purchased sZephyr assuming that it accommodates the old model AC plug. As sZephyr works only for standard type M plug pin, I had to replace my AC’s plug which cost me around 1000 rupees including electrician support.",
  },
  {
    customerName: "Ankit Tiwari",
    review:
      "sZephyr elevates AC control! Remote access, analytics, and voice commands - a perfect trio for efficiency, Reduced bills, increased comfort. Highly recommended!",
  },
  {
    customerName: "Krishna",
    review:
      "Absolutely love sZephyr! Voice-controlled AC, analytics, and scheduling - it's a smart home gem. Reduced power consumption without sacrificing comfort. Recommending to all!",
  },
  {
    customerName: "Seetharam",
    review:
      "I couldn't afford a smart AC as they are costly but with sZephyr, I bought a standard model AC and enjoying the smart features in this AC itself. Highly recommend to budget conscious consumers. Product worth of your money spent!",
  },
  {
    customerName: "Venkatesan",
    review:
      "I’ve set the schedule to turn off the AC at 3:30 in the morning but the schedule didn’t work and left the AC running. When I reported this issue, they told me to return the product which I did. But the resolution is not provided till today and no replacement product received.",
  },
  {
    customerName: "Karthikeyan",
    review:
      "I lost my AC’s remote long ago and didn’t bother to buy a new one. Now I am using sZephyr App to turn ON & OFF my AC from anywhere",
  },
];

export const VIDYURAKSHA_CUSTOMER_REVIEWS = [
  {
    customerName: "Tejashwini Ravi",
    review:
      "A Game Changer for Home Automation. A fantastic addition to any smart home ecosystem. Vidyuraksha changes the way you work with your appliances",
  },
  {
    customerName: "Mirnalini Sampath",
    review:
      "Very good product for energy usage monitoring. I purchased this for my home's motor control and it works like a charm in it's set schedule",
  },
  {
    customerName: "Gowthaman",
    review:
      "I’ve installed this product for my water heater. There are no schedules set and turn ON request is also not triggered from the App but the water heater got automatically turned ON during the time I was not in home and I was able to check this only when I returned back to home.",
  },
  {
    customerName: "Mahanthesh",
    review:
      "Vidyuraksha made my life easier. Earlier I used to control all the appliances at home manually. Now I just control everything from my smartphone. It saved my efforts painlessly",
  },
  {
    customerName: "Sivaraman",
    review:
      "I am using this product for scheduling my motor ON & OFF. The feature of auto cut-off for high/low voltage works fine and it prevents damage to my motor. Satisfied with the purchase. Now I just control everything from my smartphone. It saved my efforts painlessly",
  },
  {
    customerName: "Hariharan",
    review:
      "I've used lot of other smartplugs for various appliances but Vidyuraksha hit me differently. Features rich application. Truly outstanding features that are worth more than the money I've spent on this purchase. Recommended to all my friends",
  },
  {
    customerName: "Thamaraiselvan",
    review:
      "The build quality is really good. I accidentally dropped it from a height of 12 feet but the product didn’t get damaged and still works fine. It weighed very less comparing to the other Smartplugs. It supports only 2.4GHz Wi-Fi. Everything else is good",
  },
];

export const SMAZER_CUSTOMER_REVIEWS = [
  {
    customerName: "Dinesh Ramalingam",
    review:
      "SmaZer protects my water heater from power surges and sends alerts for preventive maintenance. Essential for a worry-free and efficient hot water experience!",
  },
  {
    customerName: "Meera Gupta",
    review:
      "SmaZer does it all - surge protection, maintenance alerts, schedules and smart remote control. My water heater's best friend! Recommending for a secure and stress-free home.",
  },
  {
    customerName: "Raghul Singh",
    review:
      "SmaZer sets the standard! A brilliant combo for water heater care. A must-have for every home aiming for appliance longevity. 100% recommended",
  },
  {
    customerName: "Preethi",
    review:
      "SmaZer ensures peace of mind! No more waiting for a hot shower. A smart water heater controller that truly understands and cares for my Geyser. Highly recommended!",
  },
  {
    customerName: "Thilak Ramanujam",
    review:
      "A reliable companion for a hassle-free hot water experience. I used to wait for 30 minutes to get the hot water ready for taking a shower. Now with Schedule option, I have my hot water ready whenever I need it! Thanks to SmaZer",
  },
  // {
  //   customerName: "Anonymous",
  //   review:
  //     "No real time monitoring of water’s temperature as advertised. Temperature is shown only when the tap is open. No indication shown for the capacity of available hot water.",
  // },
  {
    customerName: "Anonymous",
    review:
      "I am surprised by the amount of my electricity bill this month. Before installing SmaZer, I often forgot to turn off the heater after showering. Now, with SmaZer, I never forget, leading to significant energy savings.",
  },
];

// QR CODE sZephyr

export const SZEPHYR_URL_PATH = "/products/szephyrqr";



