import { useMemo } from 'react';
import * as Yup from 'yup';


export const VALIDATE_NAME = "Name is required";
export const VALIDATE_NAME_NUMBERS = "Name should not contain numbers and symbols";
export const VALIDATE_NAME_LENGTH = "Name should be at least 3 characters";
export const VALIDATE_NAME_MAX = "Name should not exceed 26 characters";
export const VALIDATE_WHITE_SPACE = "Name should not contain blank spaces"

// Form Validations Schema: Email
export const VALIDATE_EMAIL = "Email is required";
export const VALIDATE_EMAIL_INVALID = "Invalid email address";
export const NUMBER_NOT_ALLOWED_EMAIL = "Email should not start with a number";
export const SPACE_EMAIL_VALIDATION = 'Email should not contain blank spaces';

// Form Validations Schema: Description
export const VALIDATE_DESCRIPTION = "Description is required";
export const CHARACTER_VALIDATION = "Description should not exceed 100 characters";
export const DESCRIPTION_SPACE_VALIDATION = "Description should not contain blank spaces";
export const DESCRIPTION_MIN_VALIDATION = "Description should be at least 26 characters"

// Form Validations Schema: Subject
export const VALIDATE_PRODUCT = "Product type is required";


const ProductFormValidation = () => {
    const prodRegFormValidations = () => {
        return {
            country: Yup.string().required("Please select the Country"),
            productService: Yup.string().required('Please select Yes or No'),
            productType: Yup.string().required("Please select the product type"),
        }
    };

    const UseFormInitialValues = () => {
        const initialValues = {
            country: '',
            productService: '',
            productType: '',
        };
        return initialValues;
    };

    const supportFormValidations = () => {
        return {
            name: Yup.string().matches(/^[A-Za-z\s]+$/, VALIDATE_NAME_NUMBERS).max(26, VALIDATE_NAME_MAX)
                .min(3, VALIDATE_NAME_LENGTH).required(VALIDATE_NAME)
                .test(
                    'no-leading-space',
                    VALIDATE_WHITE_SPACE,
                    (value) => value && value.trim() === value
                ),
            email: Yup.string().email(VALIDATE_EMAIL_INVALID).required(VALIDATE_EMAIL)
                .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, VALIDATE_EMAIL_INVALID).max(36, VALIDATE_EMAIL_INVALID)
                .test(
                    'no-extra-dots',
                    VALIDATE_EMAIL_INVALID,
                    (value) => value && /^(?!.*\.\.)(?=.*\.com$).*$/.test(value)
                )
                .test(
                    'no-starting-number',
                    NUMBER_NOT_ALLOWED_EMAIL,
                    (value) => !/^\d/.test(value)
                ).test(
                    'no-hyphen',
                    VALIDATE_EMAIL_INVALID,
                    (value) => !/\-/.test(value)
                ).test(
                    'no-leading-space',
                    SPACE_EMAIL_VALIDATION,
                    (value) => value && value.trim() === value
                ),
            description: Yup.string().min(26, DESCRIPTION_MIN_VALIDATION).max(100, CHARACTER_VALIDATION).required(VALIDATE_DESCRIPTION)
                .test(
                    'no-leading-space',
                    DESCRIPTION_SPACE_VALIDATION,
                    (value) => value && value.trim() === value
                ),
            productType: Yup.string().required(VALIDATE_PRODUCT),
        }
    };

    const UseSupportFormInitialValues = () => {
        const initialValues = {
            name: '',
            email: '',
            description: '',
            productType: '',
        };
        return initialValues;
    };

    const UseTouchedFormInitialValues = () => {
        const initialTouched = {
            country: false, // Add other fields if needed
        };
        return initialTouched;
    };


    const prodValidationSchema = useMemo(() => {
        return Yup.object().shape(prodRegFormValidations());
    }, []);

    const supportValidationSchema = useMemo(() => {
        return Yup.object().shape(supportFormValidations());
    }, []);
    
    return {
        prodValidationSchema,
        supportValidationSchema,
        UseFormInitialValues,
        UseTouchedFormInitialValues,
        UseSupportFormInitialValues,
    };
};

export default ProductFormValidation;