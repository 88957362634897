import React, { useState } from "react";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import "./Product.css";


function FaqSmampMax(props) {
    const faqArray = [
        {
            faqTitle: "I don't have internet at home. Can I still use Vidyuraksha",
            faqAns: "Absolutely. Please note that internet is not mandatory for operation of Vidyuraksha when in closer range. However, remote connectivity needs internet"

        },
        {
            faqTitle: "Why I can't connect my Vidyuraksha with my router",
            faqAns: "Please check whether your router is 5G. Vidyuraksha works best with 4G router. You can connect to a 4G router for seamless connectivity"
        },
        {
            faqTitle: "How I can share my Vidyuraksha control with my family",
            faqAns: "Please ask them to download and install Vidyuraksha App in their smartphone. You can share your device with them using the Share icon available at the application homepage"
        },
        {
            faqTitle: "I don't have my phone with me. Can I still use Vidyuraksha",
            faqAns: "Yes. You can turn ON the AC, by pressing the power button manually"
        },
        // {
        //     faqTitle: "Where should I register my product",
        //     faqAns: "Please go to the Warranty page here to register your product"
        // },
        {
            faqTitle: "What's in the box",
            faqAns: "Box content: 1 Nos Vidyuraksha Adapter; 1 Nos product manual"
        },
        // {
        //     faqTitle: "Where should I check for the Warranty of my product",
        //     faqAns: "Please check the warranty of your Vidyuraksha in the Warranty page here (Hyperlink)"
        // },
        {
            faqTitle: "Can I install Vidyuraksha myself",
            faqAns: "Absolutely. Just plug the adapter to the wall socket and plug your appliance into the adapter. No need of an electrician for installation."
        },
        {
            faqTitle: "Can I control Vidyuraksha using my smart speaker",
            faqAns: "Yes, Vidyuraksha works with Alexa, Google Assistant and Siri"
        },
        {
            faqTitle: "LED in Vidyuraksha is continuously blinking in blue color. What does it mean",
            faqAns: "It means that Vidyuraksha has entered pairing mode. Please proceed with the steps for pairing with your smartphone"
        },
        {
            faqTitle: "Pairing is not successful. What I should do",
            faqAns: "You can retry the pairing process. Please make sure you restart the device before continuing to retry the pairing process"
        }

    ];

    const [openFaqIndex, setOpenFaqIndex] = useState(null);

    const triggerFaqDropdown = (index) => {
        setOpenFaqIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <React.Fragment>
            <section id="frequently_asked_questions">
                <div className="container-fluid d-md-flex justify-content-center flex-column align-items-center pb-5">
                    <div className="col-md-12 d-flex justify-content-center flex-column align-items-center pb-5">
                        <div className="col-md-12 text-white d-flex justify-content-center align-items-center flex-column">
                            <div className="customers_review_text">{props.title}</div>
                            <div className="customers_review_info">{props.subTitle}</div>
                        </div>
                        <div className="col-md-9 mt-5 col-sm-12 p-4 d-flex justify-content-center flex-column" id="faq_container" ref={props.refs}>
                            <div className="col-md-12 d-flex text-white justify-content-between align-items-center" onClick={props.trigger} style={{ cursor: 'pointer',width:'100%' }}>
                                <div className="col-md-10 p-2 faq_title">Vidyuraksha FAQs</div>
                                <div className="col-md-2 p-2 d-flex justify-content-center align-items-center">
                                    {props.showFaq ? <IoIosArrowDown size={30}
                                        style={{ cursor: 'pointer' }} /> : <IoIosArrowForward size={30}
                                            style={{ cursor: 'pointer' }} />}
                                </div>
                            </div>
                            <div className="col-md-12 d-flex justify-content-between align-items-center flex-row flex-wrap bg-white rounded-4">
                                {props.showFaq && faqArray.map((data, index) =>
                                    <div
                                        key={index}
                                        className={`col-md-5 p-3 text-white d-flex justify-content-center flex-column 
                                         faq_contents 
                                         ${index === 8 || index === 10 ? 'border-bottom-0' : ''
                                            }`} style={{ width: '100%' }}
                                    >
                                        <div className="faq_contents_items text-dark" style={{ height: '100%' }}>
                                            <div className="col-md-12 d-flex justify-content-start align-items-center" style={{ cursor: 'pointer' }}>
                                                <div className="col-md-10 p-2 faq_title"><strong>{data.faqTitle} ?</strong></div>
                                                <div className="col-md-2 p-2 d-flex justify-content-center align-items-center">
                                                    {/* <IoIosArrowForward size={30}
                                                        style={{ cursor: 'pointer' }} /> */}
                                                </div>
                                            </div>

                                            <div className="col-md-12 d-flex justify-content-start align-items-center">
                                                <div className="col-md-10 p-2 faq_title">{data.faqAns}</div>
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </React.Fragment>
    );
};

export default FaqSmampMax;