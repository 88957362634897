import React, { useEffect, useRef, useState } from "react";
import "./ContactUsForm.css";
import { useDispatch, useSelector } from "react-redux";
import { toggleMobileNav } from "../../redux/actions";
import ProductFormValidation from "../../customValidationHooks/ProductFormsValidations";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import AlternateFooter from "../landingPage/AlternateFooter";
import PublicHeader from "../../core/constants/PublicHeader";
import { formSubmission } from "../../core/api/apiServices";
import DropDownProducts from "../../core/constants/DropdownProducts";
import Spinner from "../../core/LoadingSpinner/Spinner";
import Swal from "sweetalert2";
import { BASE_URL2 } from "../../core/api/baseUrl";

function ContactUsForm() {
  const RESP_ERROR = "Something went wrong";
  const [isOpen, setIsOpen] = useState(false);
  const { UseSupportFormInitialValues, supportValidationSchema } =
    ProductFormValidation();
  const [isLoading, setIsLoading] = useState(false);
  const isDropDownActive = useSelector((state) => state.isProductDropdown);
  // const nameInputRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(BASE_URL2);
    // nameInputRef.current.focus();
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const mandatorySpan = <span style={{ color: "red" }}>*</span>;

  const handleSubmit = (values, { resetForm }) => {
    setIsLoading(true);
    const prodRegInfo = {
      name: values.name.charAt(0).toUpperCase() + values.name.slice(1),
      email: values.email,
      description: values.description,
      productType: values.productType,
    };

    console.log(prodRegInfo);

    formSubmission(prodRegInfo)
      .then((data) => {
        if (data) {
          Swal.fire("Thank you!", "Your submission has been sent.", "success");
          console.log(data.name);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire("Failed!", RESP_ERROR + " please try again later.", "error");
        setIsLoading(false);
      });
    resetForm();
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div id="contactus_header_top">
            <PublicHeader />
            <div className="container-fluid d-flex justify-content-center align-items-center flex-column text-white text-center">
              {isDropDownActive && (
                <div className="col-md-12">
                  <DropDownProducts />
                </div>
              )}
              <div style={{ height: "50px" }}></div>
              <div className="contactus_title text-white">Contact Us</div>
              <Formik
                initialValues={UseSupportFormInitialValues()}
                validationSchema={supportValidationSchema}
                onSubmit={handleSubmit}
              >
                {({ touched, errors }) => (
                  <div className="col-md-10 p-2 d-flex justify-content-center align-items-center flex-column main_cont">
                    <Form className=" col-md-9 d-md-flex justify-content-center flex-column align-items-center sub_cont">
                      <div className="col-md-10 d-flex justify-content-center flex-column align-items-start">
                        <label htmlFor="name" className="contactus_label pb-2">
                          Name{mandatorySpan}
                        </label>
                        <Field
                          type="text"
                          autoComplete="off"
                          spellCheck="false"
                          name="name"
                          className="form-control contactus_field text-white bg-black"
                          placeholder="Enter your name"
                          id="name"
                          // ref={nameInputRef}
                        ></Field>
                        <div
                          className="col-12 col-md-12 d-flex align-items-start"
                          style={{ height: "20px" }}
                        >
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-md-10 d-flex justify-content-center flex-column align-items-start mt-3">
                        <label htmlFor="email" className="contactus_label pb-2">
                          Email{mandatorySpan}
                        </label>
                        <Field
                          type="text"
                          autoComplete="off"
                          spellCheck="false"
                          name="email"
                          className="form-control contactus_field text-white bg-black"
                          placeholder="Enter your email"
                          id="email"
                        ></Field>
                        <div
                          className="col-12 col-md-12 d-flex align-items-start"
                          style={{ height: "20px" }}
                        >
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-md-10 d-flex justify-content-center flex-column align-items-start mt-3 select-wrapper">
                        <label htmlFor="email" className="contactus_label pb-2">
                          Description{mandatorySpan}
                        </label>
                        <Field
                          as="textarea"
                          type="text"
                          autoComplete="off"
                          spellCheck="false"
                          name="description"
                          className="form-control contactus_field text-white bg-black"
                          placeholder="Please provide description (max 100 characters)"
                          maxLength={101}
                          rows="4"
                          cols="30"
                          id="description"
                          style={{ resize: "none" }}
                        ></Field>
                        <div
                          className="col-12 col-md-12 d-flex align-items-start"
                          style={{ height: "20px" }}
                        >
                          <ErrorMessage
                            name="description"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-md-10 d-flex justify-content-center flex-column align-items-start mt-3 select-wrapper">
                        <label
                          htmlFor="Product"
                          className="contactus_label pb-2"
                        >
                          Product{mandatorySpan}
                        </label>
                        <div className="arrow-icon">
                          {/* {isOpen ? (
                            <IoIosArrowUp color="white" />
                          ) : (
                            <IoIosArrowDown color="white" />
                          )} */}
                        </div>
                        <Field
                          as="select"
                          name="productType"
                          className="form-select product_type_field text-white bg-black"
                          id="Product"
                          onClick={handleToggle}
                        >
                          <option className="text-white" value="" disabled>
                            Select one
                          </option>
                          <option className="text-white" value="smazer">
                            Smazer
                          </option>
                          <option className="text-white" value="szephyr">
                            sZephyr
                          </option>
                          <option className="text-white" value="vidyuraksha">
                            VidyuRaksha
                          </option>
                        </Field>
                        <div
                          className="col-12 col-md-12 d-flex align-items-start"
                          style={{ height: "20px" }}
                        >
                          <ErrorMessage
                            name="productType"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      {/* <div className=""> */}
                      <button
                        type="submit"
                        className="btn btn-primary search_button mt-4"
                      >
                        Submit
                      </button>
                      {/* </div> */}
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
          </div>
          <AlternateFooter />
        </>
      )}
    </React.Fragment>
  );
}

export default ContactUsForm;
