import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../../assets/homePage/company_logo.png";
import { IoMdHome } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";
import MobileNavigationHeader from "../../../core/constants/MobileNavHeader";
// import "./RailwayDomain.css";
import AlternateFooter from "../AlternateFooter";
import { toggleCloseDropdown, toggleDropdown } from "../../../redux/actions";
import DropDownProducts from "../../../core/constants/DropdownProducts";
import "./SpaceDomain.css";
import satelliteImage from "../../../assets/image 32.png";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import PublicHeader from "../../../core/constants/PublicHeader";
import { SPACE_CONTENT_LANDINGPAGE } from "../../../core/constants/Constant";

function SpaceDomain() {
  const dispatch = useDispatch();
  const isMobileNavActive = useSelector((state) => state.isMobileNavActive);
  const isDropDownActive = useSelector((state) => state.isProductDropdown);

  const handleToggleClick = () => {
    dispatch(toggleMobileNav());
  };

  const triggerDropdown = () => {
    dispatch(toggleDropdown());
  };

  // Set the duration for animations
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      dispatch(toggleCloseDropdown());
    };
  }, [dispatch]);
  return (
    <React.Fragment>
      <div className="container-fluid space_domain_top p-0">
        <PublicHeader />
        {isDropDownActive && (
          <div className="col-md-12">
            <DropDownProducts />
          </div>
        )}
        <div
          className="col-md-12 d-flex justify-content-center align-items-center flex-column text-white text-center"
          style={{ height: "90%" }}
          data-aos="fade-up"
        >
          <div className="container-fluid d-md-flex justify-content-center align-items-end flex-column">
            <div className="col-md-12 d-flex justify-content-center align-items-center flex-column">
              <div
                className="col-md-7 d-flex justify-content-end align-items-center flex-column"
                // style={{ position: 'relative' }}
              >
                <h1
                  className="railway_heading_info mb-0"
                  data-aos="fade-up"
                  // style={{ position: 'absolute', top: '40px', right: '-15%', zIndex: '-3' }}
                >
                  SPACE
                </h1>
                <div
                  className="col-md-10 col-sm-12 p-3 para_header_info d-flex justify-content-center"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  {SPACE_CONTENT_LANDINGPAGE}
                </div>
              </div>
              <div className="col-md-12 d-flex justify-content-end align-items-center space_satelite">
                <div className="col-md-5 space_satelite_main">
                  <img src={satelliteImage} className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="container-fluid d-md-flex justify-content-center bg-black">
        <div className="col-md-11 project_cloud">
          <div className="col-md-12">
            {/* <div className="col-md-7 our_projects">Our Projects:</div> */}
          </div>
          <div className="col-12 p-3 d-md-flex justify-content-center align-items-center text-white project_box space_background mt-3">
            At IINVSYS, we stand at the forefront of the cube satellite space
            industry, distinguished by our provision of state-of-the-art
            avionics hardware boards endowed with redundancy and failsafe
            attributes alongside advanced satellite on-board controller software
            that is reliable, fault tolerant, capable of multi node
            communication with encryption security. Renowned for our established
            track record and pioneering solutions, we excel in seamlessly
            integrating hardware and software offerings with end-to-end services
            tailored to a spectrum of missions. Our commitment to reliability,
            collaborative partnership, and continuous innovation makes IINVSYS
            the trusted choice for companies seeking unparalleled expertise in
            building satellites that redefine the possibilities of space
            exploration. Join us for a journey where every mission becomes a
            triumph!
          </div>
        </div>
      </section>
      <AlternateFooter />
    </React.Fragment>
  );
}

export default SpaceDomain;
