import React, { useState } from "react";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import "./Product.css";

function FaqSzephyr(props) {
  const faqArray = [
    {
      faqTitle: "How to check whether my AC is compatible with sZephyr",
      faqAns:
        "sZephyr is compatible with all models of AC including split, window and portable ACs",
    },
    {
      faqTitle: "I don't have internet at home. Can I still use sZephyr",
      faqAns:
        "Absolutely. Please note that internet is not mandatory for operation of sZephyr when in closer range. However, remote connectivity needs internet",
    },
    {
      faqTitle: "Why I can't connect my sZephyr with my router",
      faqAns:
        "Please check whether your router is 5G. sZephyr works best with 4G router. You can connect to a 4G router for seamless connectivity",
    },
    {
      faqTitle: "How I can share my sZephyr control with my family",
      faqAns:
        "Please ask them to download and install sZephyr App in their smartphone. You can share your device with them using the Share icon available at the application homepage",
    },
    {
      faqTitle: "I don't have my phone with me. Can I still use sZephyr",
      faqAns:
        "Yes. You can turn ON the AC, by pressing the power button manually",
    },
    {
      faqTitle: "How does sZephyr work",
      faqAns:
        "sZephyr senses the temperature of the room in real time and as per the desired temperature range settings configured in the App, based on the set threshold, your AC will be turned ON / OFF when the temperature reaches.",
    },
    {
      faqTitle: "How much energy is saved by using sZephyr",
      faqAns:
        "Based on the usage pattern and the desired level of comfort set by the user sZephyr aims to save maximum energy without compromising your comfort. The amount of energy saved can be seen in the application homepage itself",
    },
    // {
    //   faqTitle: "Where should I register my product",
    //   faqAns: "Please go to the Warranty page here to register your product",
    // },
    {
      faqTitle: "What's in the box",
      faqAns: "Box content: 1 Nos sZephyr Adapter; 1 Nos product manual",
    },
    // {
    //   faqTitle: "Where should I check for the Warranty of my product",
    //   faqAns:
    //     "Please check the warranty of your sZephyr in the Warranty page here (Hyperlink)",
    // },
    {
      faqTitle: "Can I install sZephyr myself",
      faqAns:
        "Absolutely! The Installation is super easy. Just take the AC's plug out from the wall socket, insert the sZephyr device into the wall socket and insert your AC's plug back into the socket of sZephyr.",
    },
    {
      faqTitle: "Can I control sZephyr using my smart speaker",
      faqAns: "Yes, sZephyr works with Alexa, Google Assistant and Siri",
    },
    {
      faqTitle:
        "LED in sZephyr is continuously blinking in blue color. What does it mean",
      faqAns:
        "It means that sZephyr has entered pairing mode. Please proceed with the steps for pairing with your smartphone",
    },
    {
      faqTitle: "Pairing is not successful. What I should do",
      faqAns:
        "You can retry the pairing process. Please make sure you restart the device before continuing to retry the pairing process",
    },
  ];

  const [openFaqIndex, setOpenFaqIndex] = useState(null);

  const triggerFaqDropdown = (index) => {
    setOpenFaqIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <React.Fragment>
      <section id="frequently_asked_questions">
        <div className="container-fluid d-md-flex justify-content-center flex-column align-items-center pb-5">
          <div className="col-md-12 d-flex justify-content-center flex-column align-items-center pb-5">
            <div className="col-md-12 text-white d-flex justify-content-center align-items-center flex-column">
              <div className="customers_review_text">{props.title}</div>
              <div className="customers_review_info">{props.subTitle}</div>
            </div>
            <div
              className="col-md-9 mt-5 col-sm-12 p-4 d-flex justify-content-center flex-column"
              id="faq_container"
              ref={props.refs}
            >
              <div
                className="col-md-12 d-flex text-white justify-content-between align-items-center"
                onClick={props.trigger}
                style={{ cursor: "pointer", width: "100%" }}
              >
                <div className="col-md-10 p-2 faq_title">Szephyr FAQs</div>
                <div className="col-md-2 p-2 d-flex justify-content-center align-items-center">
                  {props.showFaq ? (
                    <IoIosArrowDown size={30} style={{ cursor: "pointer" }} />
                  ) : (
                    <IoIosArrowForward
                      size={30}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-12 d-flex justify-content-around flex-row flex-wrap bg-white rounded-4">
                {props.showFaq &&
                  faqArray.map((data, index) => (
                    <div
                      key={index}
                      className={`col-md-5 p-3 text-white d-flex justify-content-center flex-column 
                                         faq_contents ${
                                           index === 11 || index === 13
                                             ? "border-bottom-0"
                                             : ""
                                         }`}
                      style={{ width: "100%" }}
                    >
                      <div
                        className="faq_contents_items text-dark"
                        style={{ height: "100%" }}
                      >
                        <div
                          className="col-md-12 d-flex justify-content-start align-items-center"
                          style={{ cursor: "pointer" }}
                          onClick={() => triggerFaqDropdown(index)}
                        >
                          <div className="col-md-10 p-2 faq_title">
                            <strong>{data.faqTitle} ?</strong>
                          </div>
                          <div className="col-md-2 p-2 d-flex justify-content-center align-items-center">
                            {/* <IoIosArrowForward size={30}
                                                        style={{ cursor: 'pointer' }} /> */}
                          </div>
                        </div>

                        <div className="col-md-12 d-flex justify-content-start align-items-center rounded-2">
                          <div className="col-md-10 p-2 faq_title">
                            {data.faqAns}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default FaqSzephyr;
