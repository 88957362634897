import React from "react";
import { Link } from "react-router-dom";
import smazerLogo from "../../assets/product image/smazer/smazer device.png";
import szephyrLogo from "../../assets/product image/szephyr/szephyr.png";
import smampMaxLogo from "../../assets/product image/smamp max/vidyuraksha_dropdown_logo.png";
import { useMediaQuery } from "react-responsive";

function DropDownProducts(props) {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1115px)",
  });
  return (
    <React.Fragment>
      <div
        className="dropdown_products"
        style={{ position: "relative", width: "90%" }}
      >
        <main
          className={`col-md-4 d-flex justify-content-between align-items-center bg-white rounded-4 tab_responsive ${
            !isDesktopOrLaptop ? "p-3" : "p-0"
          }`}
          style={{ position: "absolute", left: "70%", zIndex: "999" }}
        >
          <div className="col-md-12 d-flex justify-content-center flex-column">
            <Link
              to={"/szephyr-product"}
              className="col-md-12 d-flex justify-content-between dropdown_product_links"
            >
              <div className="col-md-4 dropdown_box_szephyr d-flex justify-content-center align-items-center">
                <img
                  src={szephyrLogo}
                  alt="Smazer Image"
                  style={{ width: "70%", height: "auto" }}
                />
              </div>
              <div className="col-md-7 d-flex justify-content-center flex-column">
                <div className="link_dropdown_text">sZephyr</div>
                <div className="col-md-12 link_dropdown_content">
                  The Smart Solution for Your Old ACs
                </div>
              </div>
            </Link>
            <Link
              to={"/vidyuRaksha-product"}
              className="col-md-12 d-flex justify-content-between dropdown_product_links"
            >
              <div className="col-md-4 dropdown_box_sampmax d-flex justify-content-center align-items-center">
                <img
                  src={smampMaxLogo}
                  alt="Smazer Image"
                  style={{ width: "75%", height: "auto" }}
                />
              </div>
              <div className="col-md-7 d-flex justify-content-center flex-column">
                <div className="link_dropdown_text">VidyuRaksha</div>
                <div className=" col-md-12 link_dropdown_content">
                  Make your conventional appliances Smart
                </div>
              </div>
            </Link>
            <Link
              to={"/smazer-product"}
              className="col-md-12 d-flex justify-content-between dropdown_product_links"
            >
              <div className="col-md-4 dropdown_box_smazer d-flex justify-content-center align-items-center">
                <img
                  src={smazerLogo}
                  className="smazer-img"
                  alt="Smazer Image"
                  style={{ width: "65%", height: "auto" }}
                />
              </div>
              <div className="col-md-7 d-flex justify-content-center flex-column">
                <div className="link_dropdown_text">SmaZer</div>
                <div className="col-md-12 link_dropdown_content">
                  The Best solution to make your Geyser Smart
                </div>
              </div>
            </Link>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
}

export default DropDownProducts;
