import React from "react";
import PublicHeader from "../../core/constants/PublicHeader";
import AlternateFooter from "../landingPage/AlternateFooter";
import { useSelector } from "react-redux";
import DropDownProducts from "../../core/constants/DropdownProducts";

function PrivacyPolicy() {
  const isDropDownActive = useSelector((state) => state.isProductDropdown);
  return (
    <React.Fragment>
      <div id="landing_page">
        <PublicHeader />
        {isDropDownActive && <DropDownProducts />}
        <div className="container text-white">
          <br />
          <h2>Privacy Policy</h2>
          <p>
            IINVSYS Private Limited (here is referred as IINVSYS or Company)is
            committed to respecting the privacy of our esteemed customers and
            all our product users and protecting their information. We strive to
            prevent unauthorized access or disclosure of information provided to
            us. This Privacy Policy describes our policies and practices with
            respect to your personal information.
          </p>
          <br />
          <h2>Information Collected</h2>
          <p>
            In order to provide you with the best experience with our products
            and services, we collect various types of information, including
            both Personal Information and non-personal information. Personal
            Information is any information that can be used to identify you or
            link back to you.
          </p>
          <p>
            <br />
          </p>
          <p>
            Our applications are not intended for children under 13 years of
            age, and we do not knowingly collect any personally identifiable
            information from children under 13.
          </p>
          <p>
            <br />
          </p>
          <p>
            While we strive to use commercially acceptable means to protect your
            personally identifiable information, we cannot ensure or warrant the
            security of any information you transmit to us or receive from us.
            You can help protect your information by creating a strong password
            to access your online account and by accessing your account only
            through a personal computer or device, as opposed to public
            computers or public means. When you use our applications, we may
            collect some of the Operational Data and Usage Data.
          </p>
          <p>
            <br />
          </p>
          <p>
            Operational Data is information that is needed to provide you with
            application functionality, and includes such information as your IP
            address and your login information, usage information. Operational
            data also includes information you provide to us to accept, process,
            and provide features in the products and services that you use.
          </p>
          <p>
            <br />
          </p>
          <p>
            Usage Data is information about how you interact with our
            applications, such as click paths through our applications, the
            operating system and device types you use to access our Websites, or
            our applications, your IP address, and other device usage and device
            performance information
          </p>
          <p>
            <br />
          </p>
          <br />
          <h2>Information Provided</h2>
          <p>
            We collect personal information you knowingly provide to us. For
            instance, when you register for an account on any of our Websites or
            Applications or respond to a survey, you may provide to us your
            name, address, phone number, email address, age, gender or other
            personal or payment information. When you use our application, we
            may collect the information about the products you use,for our
            application to perform analysis and automation purpose and provide
            notification and indication on how to improvise your user
            experience. When you interact with our customer service or technical
            support teams, you may also provide additional information about the
            way you are using specific products or the way your network is
            configured.
          </p>
          <p>
            <br />
          </p>
          <p>
            If you post any comments or content on our Websites or applications
            which are in public or social media platform, you should be aware
            that any personally identifiable information you choose to provide
            there may be read, collected, or used by third parties. We are not
            responsible for the information you choose to submit and we cannot
            guarantee that third parties have not made copies of or will not use
            such information in any way.
          </p>
          <p>
            <br />
          </p>
          <p>
            It is not mandatory to provide any of the personal information
            described above, but if you choose not to provide it we may not be
            able to effectively provide you with our products or services, and
            you may not be able to use portions of our application features. If
            you provide us with your personally identifiable information, you
            will be deemed to have consented to the collection, use and
            disclosure of such information as described in this Privacy Policy.
          </p>
          <p>
            <br />
          </p>
          <br />
          <h2>Information Received</h2>
          <p>
            We may receive additional information about you from other parties
            or from other applications. Any information received from third
            parties is handled and protected with at least as much care as
            information you provide directly to us or we collect automatically,
            and with any additional restrictions imposed by the provider of the
            information.
          </p>
          <br />
          <h2>Secure Information Exchange</h2>
          <ul>
            <li>
              Information exchanged on our mobile applications are secure. Our
              mobile applications and our servers are equipped with industry
              standard data encryption technology that enables anyone using our
              mobile applications to send and receive data from our servers in
              encrypted form.
            </li>
            <li>
              All of our Internet hardware is maintained in secure facilities
              accessible only to authorized, and authenticated IINVSYS employees
              and representatives.
            </li>
          </ul>
          <br />
          <h2>Usage of Personal Information</h2>
          <p>
            We may use your Personal Information for a variety of purposes,
            including without limitation in the following ways:
          </p>
          <ul>
            <li>
              To authenticate your continued access to our applications and
              devices
            </li>
            <li>
              To allow us to better service you in responding to your customer
              service requests.
            </li>
            <li>
              To personalize your application experience and to allow us to
              deliver the type of content and product offerings in which you are
              most interested.
            </li>
            <li>
              To administer a contest, promotion, survey or other application
              feature.
            </li>
            <li>
              To better serve you with analytical data for better use of our
              application and devices
            </li>
            <li>To give you promotional offers and events</li>
            <li>To analyse the data for further study</li>
            <li>
              To provide notification and feedback to you for better user
              experience
            </li>
          </ul>
          <br />
          <h2>Restrictions on Access to Personal Information</h2>
          <p>
            The Company takes the necessary steps to monitor access to personal
            information. This is done by granting, changing, and removing the
            access to database system where personal information is handled. The
            Company also deploys and uses an intrusion prevention system to
            control unauthorized access.
          </p>
          <br />
          <h2>Keeping Access Records and Preventing Tampering</h2>
          <p>
            The Company retains records of access to the personal information
            handling system (e.g. web log and summary data) for at least 6
            months and uses security features to prevent tampering, theft and
            any loss of access records.
          </p>
          <br />
          <h2>Technical Measures against Hackers</h2>
          <p>
            To prevent the loss, destruction of, or damage to personal data
            caused by hackers or computer viruses, the Company has installed
            security software that has regular updates and monitoring. In
            addition, the Company has also installed a system in off-limits
            areas for technical/physical monitoring and blocking. Furthermore,
            the Company also provides network traffic monitoring and the
            detection of any attempts to, for example, make illegal changes to
            information.
          </p>
          <br />
          <h2>Unauthorized Access Monitoring</h2>
          <p>
            To prevent the loss, destruction of or damage to personal data
            caused by hacking or computer viruses, the Company has installed
            security software and implements regular updates and monitoring. In
            addition, the Company has also installed a system in off-limits
            areas for technical/physical monitoring and blocking. Furthermore,
            the Company also provides network traffic monitoring and the
            detection of any attempts to, for example, make illegal changes to
            information.
          </p>
          <br />
          <h2>
            User&rsquo;s Rights and Rights of User&rsquo;s Legal Representative
          </h2>
          <p>
            Users and their legal representatives can access or edit their
            personal information provided to the Company at any time. Once a
            request to access, edit or delete personal information is made to
            the company by phone or by e-mail, the company will immediately
            confirm the requester&rsquo;s identity and take appropriate action.
          </p>
          <br />
          <h2>Anonymized Personal Information</h2>
          <p>
            We may anonymize your Personal Information so that it can no longer
            identify you. We may also aggregate data in a way that prevents it
            from personally identifying you. We do not link personally
            identifiable information with aggregated user data.
          </p>
          <p>
            <br />
          </p>
          <p>
            We use both personal data as well as anonymized data to monitor the
            performance of our servers and services, allowing us to proactively
            and reactively update our services to deliver a consistently high
            quality of service. For instance, we may use a combination of
            general traffic monitoring as well as IP addresses to determine if
            certain internet service providers or geographic regions have poorer
            performance and require specific performance enhancements. We will
            provide an individual opt-out choice, or opt-in for sensitive data,
            before we share your data with third parties other than our agents,
            or before we use it for a purpose other than which it was originally
            collected or subsequently authorized. To request to limit the use
            and disclosure of your personal information, please submit a written
            request to&nbsp;
          </p>
          <p>
            {" "}
            <a href="mailto:support@iinvsys.com"> support@iinvsys.com </a>{" "}
          </p>
          <p>
            In certain situations, we may be required to disclose personal data
            in response to lawful requests by public authorities, including to
            meet national security or law enforcement requirements.
          </p>
          <p>
            <br />
          </p>
          <br />
          <h2>Your Rights and Choices</h2>
          <p>
            We want you to be in control of how your personal information is
            used by us. Subject to local law, you can do this in the following
            ways:
          </p>
          <p>
            <br />
          </p>
          <p>
            You can ask us for a copy of the Personal Information we hold about
            you; You can inform us of any changes to your Personal Information,
            or if you want us to correct any of the Personal Information we hold
            about you;in certain situations, you can ask us to erase, block or
            restrict the Personal Information we hold about you, or object to
            particular ways in which we are using your Personal Information;
            andin certain situations, you can also ask us to send the Personal
            Information you have given us to a third party.Where we are using
            your Personal Information on the basis of your consent, you are
            entitled to withdraw that consent at any time. Moreover, where we
            process your Personal Information based on legitimate interest or
            the public interest, you have the right to object at any time to
            that use of your Personal Information.
          </p>
          <p>
            <br />
          </p>
          <p>
            We rely on you to ensure that your Personal Information is complete,
            accurate and current. In many cases you can update your Personal
            Information through our Websites. You can also inform us of any
            changes to, or inaccuracies of, your Personal Information by
            contacting us via email at
          </p>
          <p>
            <a href="mailto:support@iinvsys.com"> support@iinvsys.com</a>
          </p>
          <p>
            <br />
          </p>
          <br />
          <h2>Effective Date and Updates to this Policy</h2>
          <p>
            This Privacy Policy was up to date and it is effective from January
            2022. We may update the Privacy Policy at any time in our sole
            discretion. When the policy is updated, the updated policy will be
            notified to you if you had given your e-mail communication address
            with us.This Privacy Policy applies only to information collected by
            us. We are not responsible for the privacy practices of any other
            websites or applications.
          </p>
          <br />
          <h2>Notification</h2>
          <p>
            In the event of a security breach caused by hacking or computer
            virus, the Company shall immediately notify all users affected.
          </p>
          <br />
          <h2>Contact Us</h2>
          <div>
            <p>
              For questions regarding our Privacy Policy, please contact our
              customer care via email at&nbsp;
            </p>
            <p>
              {" "}
              <a href="mailto:support@iinvsys.com"> support@iinvsys.com </a>
            </p>
          </div>
        </div>
        <br/>
        <br/>
        <AlternateFooter />
      </div>
    </React.Fragment>
  );
}

export default PrivacyPolicy;
