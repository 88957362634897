import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleCloseDropdown,
  toggleDropdown,
  toggleMobileNav,
} from "../../redux/actions";
import "./Product.css";
// import plugLogo from "../../assets/smamp max/Group 160a.png";
import smazerStepTwo from "../../assets/Rectangle 5760a.png";
import smazerStepFour from "../../assets/productsPage/step_4_img.png";
import smazerStepThree from "../../assets/Group 270.png";
import numberOne from "../../assets/productsPage/one.png";
import numberTwo from "../../assets/productsPage/two.png";
import numberThree from "../../assets/productsPage/three.png";
import energyLogo from "../../assets/productsPage/energy.png";
import temperatureLogo from "../../assets/productsPage/carbon_soil-temperature.png";
import convenienceLogo from "../../assets/productsPage/convience.png";
import innovationLogo from "../../assets/productsPage/iinovation.png";
import compatibilityLogo from "../../assets/compatability_icon.png";
import appleLogo from "../../assets/productsPage/apple_ios.png";
import playStoreLogo from "../../assets/productsPage/play_store.png";
import customerComma from "../../assets/productsPage/customer_review_coma.png";
import smampmaxFeaturesBg from "../../assets/vidyuraksha_img.png";
import productAppBg from "../../assets/productsPage/vidyuraksha_app_screenshots.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import DropDownProducts from "../../core/constants/DropdownProducts";
import FaqSmampMax from "./FaqSmampMax";
import AlternateFooter from "../landingPage/AlternateFooter";
// import smampLogo from "../../assets/smampMaxLogo.png";
import smampLogo from "../../assets/vidyuraksha_title_img.png";
import PublicHeader from "../../core/constants/PublicHeader";
import CombinedSplineViewerColor from "../3dObjectFile(Threejs)/SplineViewerSmamp";
import {
  SMAMPMAX_APP_STORE,
  SMAMPMAX_PLAY_STORE,
  SMAZER_CUSTOMER_REVIEWS,
  VIDYURAKSHA_CUSTOMER_REVIEWS,
} from "../../core/constants/Constant";
// import smampmaxScreenShotOne from "../../assets/app_screenshots/smamp_max/screenshot_one.jpg";
import smampmaxScreenShotTwo from "../../assets/app_screenshots/smamp_max/screenshot_two1.png";
import smampmaxScreenShotThree from "../../assets/app_screenshots/smamp_max/screenshot_three.jpg";
import smampmaxScreenShotFour from "../../assets/app_screenshots/smamp_max/screenshot_four.jpg";
import ProductsScreenShots from "./ProductsScreenShots";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";

function SmampMaxProductPage() {
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const [activeBorder, setActiveBorder] = useState("White");
  const [loading, setLoading] = useState(true);
  const isDropDownActive = useSelector((state) => state.isProductDropdown);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images] = useState([
    // smampmaxScreenShotOne,
    smampmaxScreenShotTwo,
    smampmaxScreenShotThree,
    smampmaxScreenShotFour,
  ]);
  const [currentImage, setCurrentImage] = useState(images[currentIndex]);
  const [quantity, setQuantity] = useState(1);
  const productPrice = 3799;
  const isDesktopOrLaptop = useMediaQuery({
    query: "(max-device-width: 767px)",
  });
  const navigate = useNavigate();
  const routeName = location.pathname.split("/").pop();
  const [showFaqs, setShowFaqs] = useState(false);

  const triggerDropdownFaq = () => {
    setShowFaqs(!showFaqs);
  };

  const handleBorderClick = (deviceColor) => {
    setLoading(true);
    setActiveBorder(deviceColor);
    console.log(deviceColor);
  };

  const navigateToContactUs = (contactUsUrl) => {
    navigate(contactUsUrl);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Click outside of dropdown, close it
        // dispatch(toggleCloseDropdown());
        setShowFaqs(false);
        console.log("dropdown closed");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  // useEffect(() => {
  //   const simulateLoading = () => {
  //     setTimeout(() => {
  //       setLoading(false); // Set loading to false after some delay
  //     }, 2000); // Adjust the delay time as needed
  //   };

  //   simulateLoading(); // Simulate loading when component mounts
  // }, [activeBorder]);

  const simulateLoading = () => {
    setTimeout(() => {
      setLoading(false); // Set loading to false after some delay
    }, 2000); // Adjust the delay time as needed
  };

  // Call simulateLoading whenever activeBorder changes
  React.useEffect(() => {}, [activeBorder]);

  useEffect(() => {
    console.log(routeName);
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);
    setCurrentImage(images[currentIndex]);
    simulateLoading();
    // window.scrollTo(0, 0);
    // Cleanup function to clear interval when component unmounts
    return () => clearInterval(intervalId);
  }, [images.length, location.pathname, images, currentIndex, activeBorder]); // Re-run effect if images length changes

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const triggerDropdown = () => {
  //   dispatch(toggleDropdown());
  // };

  // const handleIncrement = () => {
  //   if (quantity < 4) {
  //     setQuantity(quantity + 1);
  //   }
  // };

  // const handleDecrement = () => {
  //   if (quantity > 1) {
  //     setQuantity(quantity - 1);
  //   }
  // };

  // const handleToggleClick = () => {
  //   dispatch(toggleMobileNav());
  // };

  // const calculateTotal = () => {
  //   return quantity * productPrice;
  // };

  useEffect(() => {
    return () => {
      dispatch(toggleCloseDropdown());
    };
  }, [dispatch]);

  const scrollToDiv = () => {
    const targetDiv = document.getElementById("product_purchase"); // Replace with the actual ID of your target div
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth" });
    }
  };

  const chunkReviews = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  const chunkedReviews = chunkReviews(VIDYURAKSHA_CUSTOMER_REVIEWS, 3);

  return (
    <React.Fragment>
      <div id="smazer_product_header_top">
        <PublicHeader />
        {isDropDownActive && <DropDownProducts />}
        <div
        // id="product_background_radial_smamp_max"
        // className="gradient-circle"
        >
          <div className="col-md-12 vidyarakshaProduct">VidyuRaksha</div>
          <div className="container-fluid prod-smazer">
            <div className="col-md-5 box-product">
              <div className="col-md-12">
                <div className="col-md-12 smazer_info text-white mx-4">
                  Make your conventional appliances{" "}
                  <span className="smart_name">Smart</span>
                  <p className="col-md-10 smart_sub_info">
                    Your ultimate destination for a smart home solution
                  </p>
                </div>
                <div className="col-md-11 bgx text-white mx-4">
                  <div
                    className="p-4 why_smazer d-flex justify-content-center align-items-center rounded-3"
                    style={{ width: "90%" }}
                  >
                    <div className="col-md-11 smazer_contents">
                      <div className="why_smazer_title"> Why VidyuRaksha?</div>
                      <div className="why_smazer_info mt-4">
                        VidyuRaksha is a powerful industrial automation
                        electrical socket, capable of supporting up to 4000W
                        power and 15A current. It safeguards against voltage
                        fluctuations and short circuits, ensuring human safety
                        and equipment reliability. It’s an excellent choice for
                        enhancing industrial safety and productivity.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 box-product">
              <div
                className="col-md-12 d-flex justify-content-end"
                // style={{ height: "65%" }}
              >
                <img
                  src={smampLogo}
                  className="img-fluid vidyuraksha_header_img"
                  alt="Your Image Alt Text"
                  style={{ marginTop: "-80px" }}
                />
              </div>
              <div className="col-md-7 d-flex justify-content-center align-items-center mt-3">
                <button
                  className="btn btn-primary buy_now_button"
                  onClick={scrollToDiv}
                >
                  Buy Now
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container d-flex flex-column justify-content-end align-items-center easy_installation_lines mt-5">
          <div className="text-center easy_installation">Easy Installation</div>
          <div className="text-center easy_installation_info">
            You are just 2 steps away to transform your conventional appliance
            into smart appliances
          </div>
        </div>
        <div className="container-fluid mt-5 mb-5 easy_install_mobile">
          <div className="col-md-12 d-flex justify-content-between align-items-center flex-row">
            <div className="col-md-4">
              <div className="col-md-2 d-flex justify-content-center flex-column align-items-center part_one_number_mobile">
                <img
                  className="img-fluid"
                  src={numberOne}
                  height={80}
                  width={80}
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="col-md-5 d-flex justify-content-end align-items-center part_one_img">
                <img
                  src={smazerStepTwo}
                  className="img-fluid"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    width: "412px",
                    maxHeight: "235px",
                  }}
                />
              </div>
              <div className="col-md-5 d-flex justify-content-start align-items-center part_one_info">
                Plug your VidyuRaksha into your wall socket
              </div>
            </div>
          </div>
          <div className="col-md-12 d-flex justify-content-between align-items-center flex-row">
            <div className="col-md-4">
              <div className="col-md-2 d-flex justify-content-center align-items-center part_two_number_mobile">
                <img
                  className="img-fluid"
                  src={numberTwo}
                  height={80}
                  width={80}
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="col-md-5 d-flex justify-content-end align-items-center part_one_img">
                <img
                  src={smazerStepThree}
                  className="img-fluid"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    width: "412px",
                    maxHeight: "235px",
                  }}
                />
              </div>
              <div className="col-md-5 d-md-flex justify-content-end align-items-center part_one_info">
                Install and connect your device.
              </div>
            </div>
          </div>
          <div className="col-md-12 d-flex justify-content-between align-items-center flex-row">
            <div className="col-md-4">
              <div className="col-md-2 d-flex justify-content-center align-items-center part_three_number_mobile">
                <img
                  className="img-fluid"
                  src={numberThree}
                  height={80}
                  width={80}
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="col-md-5 d-flex justify-content-end align-items-center part_one_img">
                <img
                  src={smazerStepFour}
                  className="img-fluid"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    width: "412px",
                    maxHeight: "235px",
                  }}
                />
              </div>
              <div className="col-md-5 d-flex justify-content-start align-items-center part_one_info">
                Plug your appliance's cord into VidyuRaksha
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid d-md-flex justify-content-center align-items-center flex-column easy_install_desktop mt-5">
          <div className="col-md-12 d-md-flex justify-content-center align-items-center">
            <div className="col-md-5 d-md-flex justify-content-end align-items-center part_one_img">
              <img
                src={smazerStepTwo}
                className="img-fluid"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  width: "412px",
                  maxHeight: "235px",
                }}
              />
            </div>
            <div className="col-md-2 d-md-flex justify-content-center align-items-center part_one_number">
              <img
                className="img-fluid"
                src={numberOne}
                height={80}
                width={80}
              />
            </div>
            <div className="col-md-5 d-md-flex justify-content-start align-items-center part_one_info">
              Plug your VidyuRaksha into your wall socket
            </div>
          </div>
          <div className="col-md-12 d-md-flex justify-content-center align-items-center mt-1">
            <div className="col-md-5 d-md-flex justify-content-end align-items-center part_one_info">
              Install and connect your device
            </div>
            <div className="col-md-2 d-md-flex justify-content-center align-items-center part_two_number">
              <img
                className="img-fluid"
                src={numberTwo}
                height={80}
                width={80}
              />
            </div>
            <div className="col-md-5 d-md-flex justify-content-start align-items-center part_one_img">
              <img
                src={smazerStepThree}
                className="img-fluid"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  width: "412px",
                  maxHeight: "235px",
                }}
              />
            </div>
          </div>
          <div className="col-md-12 d-md-flex justify-content-center align-items-center mt-1">
            <div className="col-md-5 d-md-flex justify-content-end align-items-center part_one_img">
              <img
                src={smazerStepFour}
                className="img-fluid"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  width: "412px",
                  maxHeight: "235px",
                }}
              />
            </div>
            <div className="col-md-2 d-md-flex justify-content-center align-items-center part_four_number">
              <img
                className="img-fluid"
                src={numberThree}
                height={80}
                width={80}
              />
            </div>
            <div className="col-md-5 d-md-flex justify-content-start align-items-center part_one_info">
              Plug your appliance's cord into VidyuRaksha
            </div>
          </div>
        </div>
        <section className="col-md-12 d-md-flex justify-content-center flex-column align-items-center">
          <div
            className="col-md-12 d-flex justify-content-center align-items-center"
            style={{ position: "relative" }}
          >
            <div className="text-center easy_installation features_align">
              Features
            </div>
          </div>
          <div className="container-fluid d-flex justify-content-center align-items-center">
            <div className="col-md-11 d-flex justify-content-center flex-column align-items-center">
              <img
                src={smampmaxFeaturesBg}
                className="img-fluid products_features_background"
                alt="SmAmpMax Features Background"
              />
            </div>
          </div>
        </section>
        <section className="col-md-12 d-md-flex justify-content-center flex-column align-items-center">
          <div className="text-center easy_installation">Benefits</div>
          <div
            className="col-md-10 d-md-flex justify-content-center flex-column p-2"
            id="benefit_section"
          >
            <div className="col-md-12 d-md-flex justify-content-evenly text-white mt-5 benefits_cont">
              <div className="col-md-3 text-center">
                <img
                  className="img-fluid"
                  src={energyLogo}
                  height={48}
                  width={40}
                />
                <div className="benefits_title">Energy Saving</div>
                <div className="benefits_info">
                  Schedules, timers, and auto-shut off functions, track the
                  energy usage, and get insights and recommendations to optimize
                  consumption
                </div>
              </div>
              <div className="col-md-3 text-center">
                <img
                  src={temperatureLogo}
                  height={38}
                  width={44}
                  className="img-fluid"
                />
                <div className="benefits_title">Comfort</div>
                <div className="benefits_info">
                  VidyuRaksha ensures you stay on top of your electricity bill.
                  Provides recommendation on the optimum specification of the
                  appliance to be used to ensure low energy consumption
                </div>
              </div>
              <div className="col-md-3 text-center">
                <img
                  src={convenienceLogo}
                  height={31}
                  width={31}
                  className="img-fluid"
                />
                <div className="benefits_title">Convenience</div>
                <div className="benefits_info">
                  Use VidyuRaksha app to control your appliances from anywhere,
                  voice commands to control ,group multiple appliances and
                  control them centrally.
                </div>
              </div>
            </div>
            <div className="col-md-12 d-md-flex justify-content-evenly mb-5 text-white benefits_cont">
              <div className="col-md-3 text-center">
                <img
                  src={compatibilityLogo}
                  height={35}
                  width={35}
                  className="img-fluid"
                />
                <div className="benefits_title">Compatibility</div>
                <div className="benefits_info">
                  Compatible with all types of appliances that has a type M
                  power cord. It is easy to install.
                </div>
              </div>
              <div className="col-md-3 text-center">
                <img
                  src={innovationLogo}
                  height={40}
                  width={33}
                  className="img-fluid"
                />
                <div className="benefits_title">Innovation</div>
                <div className="benefits_info">
                  Future-proof design that allows new features to be introduced
                  without any hardware changes equipped with revolutionary AI
                  Technology
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="col-md-12 mt-5 d-md-flex justify-content-center flex-column align-items-center">
          {/* <div className="text-center vidyuraksha_app_title">
            VidyuRaksha App
          </div> */}
           <div className="text-center one_app_title_app">
            <span className="one_app_text_app">VidyuRaksha</span> App
          </div>
          <div className="container-fluid d-flex justify-content-center flex-column align-items-center">
            <img
              src={productAppBg}
              className="img-fluid product_app_features_background_smpamp_max"
              alt="Smazer App Background"
            />
          </div>
          <div className="col-md-3 d-flex justify-content-evenly">
            <a
              className="col-md-8 d-flex justify-content-center apple_ios glow-button"
              href={SMAMPMAX_APP_STORE}
              target="_blank"
            >
              <div className="col-md-9 d-flex justify-content-center">
                <div className="col-md-4 d-flex justify-content-center align-items-center">
                  <img
                    src={appleLogo}
                    width={24}
                    height={26}
                    className="img-fluid"
                  />
                </div>
                <div className="col-md-11 d-flex justify-content-center flex-column">
                  <div className="text-white col-md-12 download_from_text">
                    Download From
                  </div>
                  <div className="text-white col-md-12 apple_store_text">
                    APPLE APP STORE
                  </div>
                </div>
              </div>
            </a>
            <a
              className="col-md-8 d-md-flex justify-content-center play_store glow-button"
              href={SMAMPMAX_PLAY_STORE}
              target="_blank"
            >
              <div className="col-md-9 d-flex justify-content-center">
                <div className="col-md-4 d-flex justify-content-center align-items-center">
                  <img
                    src={playStoreLogo}
                    width={23}
                    height={22}
                    className="img-fluid"
                  />
                </div>
                <div className="col-md-12 d-flex justify-content-center flex-column">
                  <div className="text-white download_from_text">
                    Download From
                  </div>
                  <div className="text-white google_play_text">
                    GOOGLE PLAY STORE
                  </div>
                </div>
              </div>
            </a>
          </div>
        </section>
        {/* Desktop */}
        <section className="col-md-12 mt-5 d-md-flex justify-content-center flex-column align-items-center smamp_product_screen_slider">
          <div className="text-center one_app_title">
            <span className="one_app_text">One App</span> Endless Possibilities
          </div>
          <div className="background_container mt-5 mb-5">
            <div className="background_image_smamp_max d-flex justify-content-center align-items-center">
              <ProductsScreenShots screenShots={currentImage} />
            </div>
          </div>
          {/* <div className="container-fluid d-flex justify-content-center flex-column align-items-center mt-5 mb-5 background_image_smamp_max">
            <ProductsScreenShots screenShots={currentImage} />
          </div> */}
        </section>
        {/* Mobile */}
        <section className="col-md-11 d-md-none d-flex justify-content-center flex-column align-items-center mb-5">
          <div className="text-center one_app_title mt-3">
            <span className="one_app_text">One App</span> Endless Possibilities
          </div>
          {/* <div
            className="col-9 background-image-mobile"
            style={{ height: "60vh" }}
          > */}
          <ProductsScreenShots screenShots={currentImage} />
          {/* </div> */}
        </section>
        <section
          className="col-12 d-flex justify-content-center mt-3"
          id="product_purchase"
        >
          <div className="col-md-11 d-md-flex justify-content-center flex-row sampmax_details_info mb-5">
            {/* <div className="col-md-4 d-flex justify-content-center align-items-start">
              <div
                className="container-fluid d-flex justify-content-center align-items-center flex-column mobile_view_3d"
                style={{ height: "60%", background: "transparent" }}
              >
                {
                  <CombinedSplineViewerColor
                    colorType={activeBorder}
                    setLoading={setLoading}
                  />
                }
                {loading && (
                  <div className="col-12 fs-5 text-white d-flex justify-content-center align-items-center">
                    Loading...
                  </div>
                )}
              </div>
            </div> */}
            <div className="col-md-4 d-flex justify-content-center align-items-start mt-5">
              <div
                className="container-fluid spline_3d"
                style={{ height: "60%", background: "transparent" }}
              >
                {
                  <CombinedSplineViewerColor
                    colorType={activeBorder}
                    setLoading={setLoading}
                  />
                }
                {loading && (
                  <div className="col-12 fs-5 text-white d-flex justify-content-center align-items-center">
                    Loading...
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-8 d-md-flex p-1 justify-content-center align-items-center flex-column text-white mt-5 mb-5">
              <div className="col-md-11 mb-3">
                <div className="product_names">Vidyuraksha</div>
                <div className="product_name_info">Smart home companion</div>
                <div className="col-md-4 col-sm-8 d-md-flex justify-content-between align-items-center">
                  {/* <div className="product_prices">Rs 3799</div>
                  <div className="price_slashed">Rs 5000</div> */}
                </div>
              </div>
              <div className="col-md-11 product_details_listing">
                <ul className="product_listing_details mt-3">
                  <li>
                    Vidyuraksha is a cost-effective solution that lets you
                    monitor & control your conventional appliances. Smart
                    features of remote control, scheduling & automation can be
                    introduced to your conventional appliances to make them
                    smart.
                  </li>
                  <li style={{ width: "90%" }}>
                    Vidyuraksha is a durable product that comes with a 1-year
                    warranty. It is made in India with high-quality materials
                    and components. It is CE certified and meets all the safety
                    standards
                  </li>
                  <li>Space-saving design</li>
                  <li>Colorful LED indicators</li>
                  <li>FR grade quality – No more worries of fire hazards </li>
                </ul>
                <div className="col-md-11 mx-3">
                  <div className="d-md-flex justify-content-start align-items-center">
                    <div className="col-md-12">
                      <div className="product_color_text">
                        Color: {activeBorder}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 d-flex justify-content-start align-items-center gap-2 pt-2">
                    <div
                      className={`White ${
                        activeBorder === "White" ? "actives" : ""
                      }`}
                      onClick={() => handleBorderClick("White")}
                    >
                      <div className="color_section_one"></div>
                    </div>
                    <div
                      className={`Blue ${
                        activeBorder === "Blue" ? "actives" : ""
                      }`}
                      onClick={() => handleBorderClick("Blue")}
                    >
                      <div className="color_section_two"></div>
                    </div>
                    <div
                      className={`Green ${
                        activeBorder === "Green" ? "actives" : ""
                      }`}
                      onClick={() => handleBorderClick("Green")}
                    >
                      <div className="color_section_three"></div>
                    </div>
                    <div
                      className={`Grey ${
                        activeBorder === "Grey" ? "actives" : ""
                      }`}
                      onClick={() => handleBorderClick("Grey")}
                    >
                      <div className="color_section_four"></div>
                    </div>
                  </div>
                  {/* <div className="choose_quantity">
                    <div className="quantity_text">Quantity</div>
                    <div className="col-md-3 quantity_btns d-flex justify-content-between align-items-center mt-2">
                      <button
                        className="col-md-3 d-flex justify-content-center align-items-center quantity_button_select"
                        onClick={handleDecrement}
                        disabled={quantity === 1}
                      >
                        <FaMinus color="#3a3a3a" />
                      </button>
                      <div className="quantitiy_number">{quantity}</div>
                      <button
                        className="col-md-3 d-flex justify-content-center align-items-center quantity_button_select"
                        onClick={handleIncrement}
                        disabled={quantity === 4}
                      >
                        <FaPlus color="#3a3a3a" />
                      </button>
                    </div>
                  </div> */}
                </div>
                <div className="col-md-12 mt-4 mx-3">
                  {/* <div className="col-md-11 d-flex justify-content-center order_details">
                                        <div className="col-md-11">
                                            <div className="col-md-12 d-flex justify-content-between p-3">
                                                <div className="col-md-8 product_orders_text d-flex justify-content-start align-items-center">Vidyuraksha *{quantity} :</div>
                                                <div className="col-md-4 d-flex justify-content-center align-items-center product_orders_price">Rs {productPrice}</div>
                                            </div>
                                            <div className="col-md-12 d-flex justify-content-between p-3">
                                                <div className="col-md-8 product_total d-flex justify-content-start align-items-center">Total :</div>
                                                <div className="col-md-4 d-flex justify-content-center align-items-center total_order_price">Rs {calculateTotal()}</div>
                                            </div>
                                        </div>
                                    </div> */}
                  <Link to={`/contact-us`} className="mt-3">
                    <button className="col-md-11 btn btn-primary buy_now_button buy_button_text">
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="col-md-12 d-md-flex justify-content-center flex-column align-items-center">
          <div className="col-md-12 text-white d-md-flex justify-content-center align-items-center flex-column">
            <div className="customers_review_text">Customers Reviews</div>
            <div className="customers_review_info text-center">
              Let's hear what our customers says about the product
            </div>
          </div>
          <Swiper
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
            style={{ width: "90%" }}
          >
            {chunkedReviews.map((reviewChunk, index) => (
              <SwiperSlide
                key={index}
                className="container-fluid p-5 d-flex justify-content-center"
              >
                <div className="row d-md-flex justify-content-center align-items-center">
                  {reviewChunk.map((review, subIndex) => (
                    <div key={subIndex} className="col-12 col-md-4">
                      <div className="customer_review d-flex justify-content-center align-items-center text-white">
                        <div className="col-md-2 review_logo">
                          <img
                            src={customerComma}
                            className="img-fluid"
                            alt="Review logo"
                          />
                        </div>
                        <div
                          className="col-md-10 d-flex justify-content-evenly flex-column align-items-center tablet_view"
                          style={{ height: "40vh" }}
                        >
                          <div className="customer_review_user text-center">
                            {review.customerName}
                          </div>
                          <div
                            className="col-md-12 col-sm-12 customer_review_info text-center tablet_view"
                            style={{ height: "60%", width: "100%" }}
                          >
                            {review.review}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <FaqSmampMax
          title={"FAQs"}
          subTitle={"We care about your questions"}
          refs={dropdownRef}
          showFaq={showFaqs}
          trigger={triggerDropdownFaq}
        />
      </div>
      <AlternateFooter />
    </React.Fragment>
  );
}

export default SmampMaxProductPage;
