import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SzephyrRedirectApp = () => {
  const [platform, setPlatform] = useState("");
  const platformAndroid = "https://play.google.com/store/apps/details?id=com.iinvsys.szephyr";
  const platformIos = "https://apps.apple.com/us/app/szephyr/id6447295364";

  const platformDetect = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      setPlatform("Android");
      window.location.href = platformAndroid;
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setPlatform("iOS");
      window.location.href = platformIos;
    } else if (/Macintosh/.test(userAgent) && "ontouchend" in document) {
      setPlatform("iOS");
      window.location.href = platformIos;
    } else if (/Macintosh/.test(userAgent)) {
      setPlatform("Mac");
      window.location.href = platformIos;
    } else {
      setPlatform("Desktop");
      window.location.href = platformAndroid;
    }
  };

  useEffect(() => {
    platformDetect();
  }, []);

  return (
    <React.Fragment>
      {/* <p>This app is running on: {platform}</p> */}
    </React.Fragment>
  );
};

export default SzephyrRedirectApp;
