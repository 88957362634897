import React, { useEffect } from "react";
import "../../componentStyles/landingPageStyle/Visions.css";
import Domain from "./Domain";
import PureCounter from "../../core/constants/PureCounter";
import { useMediaQuery } from "react-responsive";
import {
  MISSION_CONTENTS,
  VISION_CONTENTS,
} from "../../core/constants/Constant";

function Visions() {
  const isTabletPortrait = useMediaQuery({
    query:
      "(min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1)",
  });

  return (
    <React.Fragment>
      <div id="section_two">
        <div className="col-md-12 text-center user_review d-flex justify-content-center flex-column">
          <div
            id="vision_mission_contents"
            className={`${isTabletPortrait ? "col-md-12" : "col-md-10"}`}
            data-aos="fade-up"
          >
            {/* <div className="row align-items-start  mx-5">
              <div className="col-md-4 d-flex justify-content-start flex-column">
                <div className="border_user col-12">
                  <h6 className="device_used">Devices Installed</h6>
                </div>
                <div className="col-12">
                  <h1 className="device_numbers">
                    <PureCounter start={0} end={800} duration={1000} />+
                  </h1>
                </div>
              </div>
              <div className="col-md-4 d-flex justify-content-start flex-column">
                <div className="border_user col-12">
                  <h6 className="device_used">Happy Customers</h6>
                </div>
                <div className="col-12">
                  <h1 className="device_numbers">
                    <PureCounter start={0} end={1000} duration={1000} />+
                  </h1>
                </div>
              </div>
              <div className="col-md-4 d-flex justify-content-start flex-column">
                <div className="border_user col-12">
                  <h6 className="device_used">
                    Precaution & Maintenance Alert
                  </h6>
                </div>
                <div className="col-12">
                  <h1 className="device_numbers">
                    <PureCounter start={0} end={250} duration={1000} />+
                  </h1>
                </div>
              </div>
            </div> */}
            {/* <div className="row align-items-start mt-3 mx-5">
              <div className="col-md-4 d-flex justify-content-start flex-column">
                <div className="border_user col-12">
                  <h6 className="device_used">Customer Satisfaction</h6>
                </div>
                <div className="col-12">
                  <h1 className="device_numbers">
                    <PureCounter start={0} end={100} duration={1000} />%
                  </h1>
                </div>
              </div>
              <div className="col-md-4 d-flex justify-content-start flex-column">
                <div className="border_user col-12">
                  <h6 className="device_used">Units of Electrical Saved</h6>
                </div>
                <div className="col-12">
                  <h1 className="device_numbers">
                    <PureCounter start={0} end={5000} duration={1000} />
                    +kWh
                  </h1>
                </div>
              </div>
              <div className="col-md-4 d-flex justify-content-start flex-column">
                <div className=" border_user col-12">
                  <h6 className="device_used">Domains Covered</h6>
                </div>
                <div className="col-12">
                  <h1 className="device_numbers">
                    <PureCounter start={0} end={4} duration={1000} />+
                  </h1>
                </div>
              </div>
            </div> */}
            <div className="col-12 d-flex justify-content-center align-items-center visions_info mt-5">
              <h1
                className="vision_text"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Our <span className="vision_text_alt">Vision</span>
              </h1>
              <div
                className={`${
                  isTabletPortrait
                    ? "col-md-11 vision_text_alter"
                    : "col-md-8 vision_text_alter"
                }`}
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <p className="col-12 visions_para mt-3">{VISION_CONTENTS}</p>
              </div>
            </div>
            <div className="col-md-12 d-flex justify-content-center align-items-center visions_info ">
              <h1
                className="vision_text"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Our <span className="vision_text_alt">Mission</span>
              </h1>
              <div
                className={`${
                  isTabletPortrait
                    ? "col-md-11 vision_text_alter"
                    : "col-md-8 vision_text_alter"
                }`}
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <p className="col-12 visions_para ">{MISSION_CONTENTS}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Visions;
