import React from "react";
import "../../componentStyles/landingPageStyle/Support.css";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
// import { FaPhoneAlt } from "react-icons/fa";
import { LuPhone } from "react-icons/lu";
import { MdOutlineMailOutline } from "react-icons/md";
import smilySupportLogo from "../../assets/supportPage/supportOptions/chat_support_smily.png";
import phoneLogo from "../../assets/supportPage/supportOptions/phone.png";
import emailLogo from "../../assets/supportPage/supportOptions/email.png";

function Support() {
  return (
    // <div id="container_support">
    //   <div className="container" data-aos="fade-up">
    //     <div className="row align-items-center justify-content-center">
    //       {/* <div className="col-md-4 ">
    //         <div className="full-width-section">
    //           <div className="icon">
    //             <img
    //               src={smilySupportLogo}
    //               className="img-fluid"
    //               width={70}
    //               height={69}
    //               data-aos="zoom-out"
    //               data-aos-delay="100"
    //             />
    //           </div>
    //           <div className="heading">Chat Support</div>
    //           <div className="paragraph">
    //             Have queries on our products & services?
    //           </div>
    //           <div className="paragraph">
    //             Get your answers from the experts with the <br />
    //             comfort of chat
    //           </div>
    //           <a
    //             className="button"
    //             style={{ cursor: "pointer" }}
    //             href="https://wa.me/7010633286"
    //           >
    //             Chat Now
    //           </a>
    //         </div>
    //       </div> */}
    //       {/* <div class="col-md-4">
    //         <div className="full-width-section">
    //           <div className="icon">
    //             <img
    //               src={phoneLogo}
    //               className="img-fluid"
    //               width={70}
    //               height={69}
    //               data-aos="zoom-out"
    //               data-aos-delay="100"
    //             />
    //           </div>
    //           <div className="heading">Phone Support</div>
    //           <div className="paragraph">
    //             Want to speak to us? We would love to hear from you.
    //           </div>
    //           <div className="paragraph">
    //             please give us a call at our toll free Number +91 XXXXXXXXXX
    //           </div>
    //           <a className="button" style={{ cursor: "pointer" }}>
    //             Call Now
    //           </a>
    //         </div>
    //       </div> */}
    //       <div class="col-md-4">
    //         <div className="full-width-section" style={{ border: "none" }}>
    //           <div className="icon">
    //             <img
    //               src={emailLogo}
    //               className="img-fluid"
    //               width={70}
    //               height={69}
    //               data-aos="zoom-out"
    //               data-aos-delay="100"
    //             />
    //           </div>
    //           <div className="heading">Mail Support</div>
    //           <div className="paragraph">
    //             Write to us at support@iinvsys.com
    //           </div>
    //           <a
    //             className="button"
    //             style={{ cursor: "pointer" }}
    //             onClick={() => {
    //               window.open("mailto:support@iinvsys.com");
    //             }}
    //           >
    //             Mail Now
    //           </a>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <></>
  );
}

export default Support;
