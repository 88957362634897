import React, { useEffect } from "react";
import Logo from "../../assets/homePage/company_logo.png";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { toggleCloseDropdown, toggleDropdown } from "../../redux/actions";

function HeaderComponent() {
    const dispatch = useDispatch();
    const isDropDownActive = useSelector((state) => state.isProductDropdown);
    const isMobileNavActive = useSelector((state) => state.isMobileNavActive);

    const triggerDropdown = () => {
        dispatch(toggleDropdown());
    };

    useEffect(() => {
        return () => {
            dispatch(toggleCloseDropdown());
        };
    }, [dispatch]);

    return (
        <div className="col-md-12 d-flex justify-content-center align-items-center p-3">
            <div className="col-md-10 d-flex justify-content-between align-items-center" style={{padding:'10px 0px '}}>
                <div className="col-md-12 d-md-flex justify-content-evenly align-items-end">
                    <div className="col-7 d-flex justify-content-between ">
                        <img src={Logo} alt="company_logo" className="image-fluid company_logo" />
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '50px', border: '1px solid #ccc' }}>
                        </div>
                        <h1 className="text-white text-center d-flex justify-content-center align-items-end mb-0" style={{ fontSize: '20px' }}>Democratizing Technology,<span className="info_text mx-1">Driving Innovation</span></h1>
                    </div>
                    <div className="col-md-5 text-white d-flex justify-content-center align-items-end gap-4">
                        <NavLink to={"/"} className="header_navigations">
                            Home
                        </NavLink>
                        <Link onClick={triggerDropdown} style={{ color: `${isDropDownActive ? '#055783c7' : ''}` }}className="header_navigations">Products{" "}
                            <span className="">{!isDropDownActive ? <IoIosArrowForward size={15} color="#fff" /> : <IoIosArrowDown size={15} color="#fff" />}</span></Link>
                        <NavLink to={"/aboutus-page"} className="header_navigations">About Us</NavLink>
                    </div>
                </div>
                {/* <a href="/" className="logo d-flex justify-content-center align-items-center col-md-2">
                <img src={Logo} alt="company_logo" className="image-fluid company_logo" />
            </a>
            <div className="col-md-4 d-flex justify-content-center align-items-end" style={{ height: '50px' }}>
                <h1 className="text-white text-center" style={{ fontSize: '20px' }}>Democratizing Technology, <span className="info_text">Driving Innovation</span></h1>
            </div> */}
            </div >
        </div>
    );
}

export default HeaderComponent;