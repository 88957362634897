import Spline from "@splinetool/react-spline";
import React from "react";

export default function CombinedSmazerColor({ colorType }) {
  return (
    <React.Fragment>
      {colorType === "White" && <SplineViewerSmazerWhite />}
      {colorType === "Grey" && <SplineViewerSmazerGrey />}
      {colorType === "Green" && <SplineViewerSmazerGreen />}
      {colorType === "Blue" && <SplineViewerSmazerBlue />}
    </React.Fragment>
  );
}

function SplineViewerSmazerWhite() {
  return (
    <Spline scene="https://prod.spline.design/CnKErpgpHHW3ElpD/scene.splinecode" />
  );
}

function SplineViewerSmazerGrey() {
  return (
    <Spline scene="https://prod.spline.design/PkWCvgowjw1IPYqD/scene.splinecode" />
  );
}

function SplineViewerSmazerBlue() {
  return (
    <Spline scene="https://prod.spline.design/gDmDHKhlALXcjG7z/scene.splinecode" />
  );
}

function SplineViewerSmazerGreen() {
  return (
    <Spline scene="https://prod.spline.design/t1QpJV9YMhwYZkOz/scene.splinecode" />
  );
}

