import React from "react";
import Spline from "@splinetool/react-spline";

export default function CombinedSplineViewerColor({ colorType }) {
  return (
    <React.Fragment>
      {colorType === "White" && <SplineViewerSmamp />}
      {colorType === "Grey" && <SplineViewerSmampGrey />}
      {colorType === "Green" && <SplineViewerSmampGreen />}
      {colorType === "Blue" && <SplineViewerSmampBlue />}
      {/* {colorType === "splineBottle" && <SampleSplineBottle />} */}
    </React.Fragment>
  );
}

function SplineViewerSmamp() {
  // return (
  //   <Spline scene="https://prod.spline.design/mSUJsEnRS8UeX2uq/scene.splinecode" />
  // );
  return (
    // <Spline scene="https://prod.spline.design/mSUJsEnRS8UeX2uq/scene.splinecode" />

    <Spline scene="https://prod.spline.design/dD5azu6PHg7QOf0U/scene.splinecode" />
  );
}

function SplineViewerSmampBlue() {
  return (
    // <Spline scene="https://prod.spline.design/lnBSrI9J2zzJR9fM/scene.splinecode" />
    <Spline scene="https://prod.spline.design/H3nfj-bdIdTMlfWK/scene.splinecode" />
  );
}

function SplineViewerSmampGreen() {
  return (
    <Spline scene="https://prod.spline.design/Nx0hvXsxC-2DWfuJ/scene.splinecode" />
  );
}

function SplineViewerSmampGrey() {
  return (
    <Spline scene="https://prod.spline.design/TFUZo3Mq2hX6YnZY/scene.splinecode" />
  );
}

// function SampleSplineBottle() {
//   return (
//     <Spline scene="https://prod.spline.design/GhKeH4Ht6-vKgPE3/scene.splinecode" />
//   );
// }
