import React from "react";
import { Link } from "react-router-dom";

function PageNotFound() {
  return (
    <div>
      <div className="container-fluid vh-100 d-flex justify-content-center align-items-center bg-black">
        <div className="col-md-10 d-flex justify-content-center align-items-center flex-column">
          {/* <div className="col-md-6 offset-md-3 border text-center"> */}
          <h1
            className="col-12 pageNotFoundContents text-center"
            style={{ fontSize: "90px" }}
          >
            404 Not Found
          </h1>
          <p
            className="lead pageNotFoundContents text-center mt-3"
            style={{ fontWeight: "500", fontSize: "16px" }}
          >
            Your visited page not found. You may go home page.
          </p>
          <Link to={"/"}>
            <button className="btn btn-primary buy_now_button buy_button_text mt-3">
              Back to Home Page
            </button>
          </Link>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
